import React, { useState } from 'react';
import './KrediKartıGecikmeFaizi.css';
import { formatNumber } from './utils/format';


const KrediKartıGecikmeFaizi = () => {
  const [borc, setBorc] = useState('');
  const [faizOrani, setFaizOrani] = useState('');
  const [gecikmeSuresi, setGecikmeSuresi] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [error, setError] = useState('');

  const handleHesapla = (e) => {
    e.preventDefault();
    setError(''); // Önceki hatayı temizle

    const b = parseFloat(borc);
    const f = parseFloat(faizOrani) / 100;
    const g = parseInt(gecikmeSuresi);

    if (!b || !f || !g || b <= 0 || f <= 0 || g <= 0) {
      setError('Lütfen tüm alanları geçerli değerlerle doldurun!');
      return;
    }

    const gecikmeFaizi = (b * f * g) / 365;

    setSonuc({
      gecikmeFaizi: gecikmeFaizi.toFixed(2),
      toplamBorc: (b + gecikmeFaizi).toFixed(2),
    });
  };

  const handleTemizle = () => {
    setBorc('');
    setFaizOrani('');
    setGecikmeSuresi('');
    setSonuc(null);
    setError('');
  };

  return (
    <div className="kredi-karti-container">
      <h1 className="kredi-karti-title">Kredi Kartı Gecikme Faizi Hesaplama</h1>
      <p style={{ textAlign: 'center', marginBottom: '30px',fontSize: '18px', fontFamily:'Poppins' }}>
      Kredi kartınızın gecikme faizini bu araç ile hesaplayabilirsiniz.
      </p>
      <form className="kredi-karti-form" onSubmit={handleHesapla}>
        <label>
          * Kredi Kartı Borcu (TL):
          <input
            type="number"
            value={borc}
            onChange={(e) => setBorc(e.target.value)}
            placeholder="Örn: 1000"
          />
        </label>
        <label>
          * Gecikme Faizi Oranı (%):
          <input
            type="number"
            step="0.01"
            value={faizOrani}
            onChange={(e) => setFaizOrani(e.target.value)}
            placeholder="Örn: 2.50"
          />
        </label>
        <label>
          * Gecikme Süresi (Gün):
          <input
            type="number"
            value={gecikmeSuresi}
            onChange={(e) => setGecikmeSuresi(e.target.value)}
            placeholder="Örn: 30"
          />
        </label>
        {error && <p className="error-message">{error}</p>}
        <div className="button-group">
          <button type="submit" className="hesapla-button">Hesapla</button>
          <button type="button" className="temizle-button" onClick={handleTemizle}>Temizle</button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-card">
          <h2>Sonuçlar</h2>
          <p><strong>Gecikme Faizi:</strong> {formatNumber(sonuc.gecikmeFaizi)} TL</p>
          <p><strong>Toplam Borç (Faiz Dahil):</strong> {formatNumber(sonuc.toplamBorc)} TL</p>
        </div>
      )}
    </div>
  );
};

export default KrediKartıGecikmeFaizi;
