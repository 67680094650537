import React, { useState } from 'react';
import './dogumtarihihesaplama.css';

const DogumTarihiHesaplama = () => {
  const [tarih, setTarih] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setSonuc(null);

    if(!tarih) {
      setHata('Lütfen son adet tarihini seçin!');
      return;
    }

    const baslangicTarihi = new Date(tarih);
    const tahminiDogumTarihi = new Date(baslangicTarihi);
    tahminiDogumTarihi.setDate(tahminiDogumTarihi.getDate() + 280); // 40 hafta = 280 gün

    const bugun = new Date();
    const kalanGun = Math.ceil((tahminiDogumTarihi - bugun) / (1000 * 60 * 60 * 24));

    setSonuc({
      tahminiTarih: tahminiDogumTarihi.toLocaleDateString('tr-TR'),
      kalanGun: kalanGun > 0 ? kalanGun : 0
    });
  };

  const temizle = () => {
    setTarih('');
    setSonuc(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container dogum-tarihi">
      <div className="baslik-bolumu">
        <h1>🏩 Tahmini Doğum Tarihi Hesaplama</h1>
        <p>Son adet tarihini girerek hesaplama yapın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubu">
          <label>
            Son Adet Tarihiniz
            <input
              type="date"
              value={tarih}
              onChange={(e) => setTarih(e.target.value)}
              className="tarih-input"
              max={new Date().toISOString().split('T')[0]}
            />
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-paneli">
          <h2>Hesaplama Sonuçları</h2>
          <div className="sonuc-item">
            <span>Tahmini Doğum Tarihi:</span>
            <div className="vurgulu">{sonuc.tahminiTarih}</div>
          </div>
          <div className="sonuc-item">
            <span>Kalan Gün Sayısı:</span>
            <div className="vurgulu">{sonuc.kalanGun} Gün</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DogumTarihiHesaplama;