import React, { useState } from 'react';
import './AlanHesaplama.css';

const AlanHesaplama = () => {
  const [sekil, setSekil] = useState('dikdortgen');
  const [olculer, setOlculer] = useState({
    taban: '',
    yukseklik: '',
    yaricap: ''
  });
  const [alan, setAlan] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'sekil') {
      setSekil(value);
      setAlan(null);
      setOlculer({ taban: '', yukseklik: '', yaricap: '' });
    } else {
      setOlculer({
        ...olculer,
        [name]: value
      });
    }
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setAlan(null);

    // Validasyon
    let error = '';
    switch(sekil) {
      case 'dikdortgen':
        if(!olculer.taban || !olculer.yukseklik) error = 'Lütfen tüm alanları doldurun!';
        break;
      case 'ucgen':
        if(!olculer.taban || !olculer.yukseklik) error = 'Lütfen tüm alanları doldurun!';
        break;
      case 'daire':
        if(!olculer.yaricap) error = 'Lütfen yarıçap değerini girin!';
        break;
    }

    if(error) {
      setHata(error);
      return;
    }

    // Hesaplama
    let calculatedArea;
    const taban = parseFloat(olculer.taban);
    const yukseklik = parseFloat(olculer.yukseklik);
    const yaricap = parseFloat(olculer.yaricap);

    switch(sekil) {
      case 'dikdortgen':
        calculatedArea = taban * yukseklik;
        break;
      case 'ucgen':
        calculatedArea = (taban * yukseklik) / 2;
        break;
      case 'daire':
        calculatedArea = Math.PI * Math.pow(yaricap, 2);
        break;
    }

    setAlan(calculatedArea.toFixed(2));
  };

  const temizle = () => {
    setSekil('dikdortgen');
    setOlculer({ taban: '', yukseklik: '', yaricap: '' });
    setAlan(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container alan">
      <div className="baslik-bolumu">
        <h1>📐 Geometrik Alan Hesaplama</h1>
        <p>Şekil seçerek alan hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubuu">
          <label>
            Şekil Seçin
            <select
              name="sekil"
              value={sekil}
              onChange={handleChange}
              className="tarih-input"
            >
              <option value="dikdortgen">Dikdörtgen</option>
              <option value="ucgen">Üçgen</option>
              <option value="daire">Daire</option>
            </select>
          </label>
        </div>

        <div className="input-gridd">
          {sekil !== 'daire' && (
            <>
              <div className="input-grubuu">
                <label>
                  Taban (birim)
                  <input
                    type="number"
                    name="taban"
                    value={olculer.taban}
                    onChange={handleChange}
                    min="0.1"
                    step="0.1"
                    className="tarih-input"
                    placeholder="Ör: 5"
                  />
                </label>
              </div>
              <div className="input-grubuu">
                <label>
                  Yükseklik (birim)
                  <input
                    type="number"
                    name="yukseklik"
                    value={olculer.yukseklik}
                    onChange={handleChange}
                    min="0.1"
                    step="0.1"
                    className="tarih-input"
                    placeholder="Ör: 10"
                  />
                </label>
              </div>
            </>
          )}
          
          {sekil === 'daire' && (
            <div className="input-grubuu">
              <label>
                Yarıçap (birim)
                <input
                  type="number"
                  name="yaricap"
                  value={olculer.yaricap}
                  onChange={handleChange}
                  min="0.1"
                  step="0.1"
                  className="tarih-input"
                  placeholder="Ör: 7"
                />
              </label>
            </div>
          )}
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {alan && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Alan</h2>
          <div className="alan-sonuc">
            <span className="alan-deger">{alan}</span>
            <span className="alan-birim">birim²</span>
          </div>
          <p className="uyari">
            * Sonuçlar 2 ondalık basamağa yuvarlanmıştır
          </p>
        </div>
      )}
    </div>
  );
};

export default AlanHesaplama;