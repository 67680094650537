import React, { useState } from 'react';
import './UsluHesaplama.css';

const UsluHesaplama = () => {
  const [inputs, setInputs] = useState({ taban: '', us: '' });
  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setSonuc(null);

    const taban = parseFloat(inputs.taban);
    const us = parseFloat(inputs.us);

    // Validasyonlar
    if (inputs.taban === '' || inputs.us === '') {
      setHata('Lütfen tüm alanları doldurun!');
      return;
    }

    if (taban === 0 && us <= 0) {
      setHata('0\'ın negatif veya 0. üssü tanımsızdır!');
      return;
    }

    // Hesaplama
    try {
      const result = Math.pow(taban, us);
      setSonuc(result);
    } catch (error) {
      setHata('Geçersiz hesaplama!');
    }
  };

  const temizle = () => {
    setInputs({ taban: '', us: '' });
    setSonuc(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container uslu">
      <div className="baslik-bolumu">
        <h1>🔢 Üslü Sayı Hesaplama</h1>
        <p>Taban ve üs değerlerini girerek sonucu bulun</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              Taban
              <input
                type="number"
                name="taban"
                value={inputs.taban}
                onChange={handleChange}
                step="any"
                className="tarih-input"
                placeholder="Ör: 2"
              />
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              Üs
              <input
                type="number"
                name="us"
                value={inputs.us}
                onChange={handleChange}
                step="any"
                className="tarih-input"
                placeholder="Ör: 5"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc !== null && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Sonuç</h2>
          <div className="uslu-sonuc">
            <span className="taban">{inputs.taban}</span>
            <sup className="us">{inputs.us}</sup> = 
            <span className="sonuc-deger">
              {parseFloat(sonuc.toFixed(6))}
            </span>
          </div>
          <p className="uyari">
            * Sonuçlar 6 ondalık basamağa yuvarlanmıştır
          </p>
        </div>
      )}
    </div>
  );
};

export default UsluHesaplama;