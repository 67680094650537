import React, { useState } from 'react';
import './KDVHesaplama.css';

const KDVHesaplama = () => {
  const [mode, setMode] = useState('dahilden');
  const [kdvOrani, setKdvOrani] = useState(18);
  const [tutar, setTutar] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setSonuc(null);

    if(!tutar || tutar <= 0) {
      setHata('Lütfen geçerli bir tutar girin!');
      return;
    }

    const numTutar = parseFloat(tutar);
    const oran = kdvOrani / 100;

    let calculation;
    switch(mode) {
      case 'dahilden':
        calculation = numTutar / (1 + oran);
        break;
      case 'haricten':
        calculation = numTutar * (1 + oran);
        break;
      case 'matrah':
        calculation = numTutar / oran;
        break;
      default:
        calculation = 0;
    }

    setSonuc(calculation.toFixed(2));
  };

  const temizle = () => {
    setTutar('');
    setSonuc(null);
    setHata('');
  };

  const formatTL = (value) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY'
    }).format(value);
  };

  return (
    <div className="hesaplama-container kdv">
      <div className="baslik-bolumu">
        <h1>🧮 KDV Hesaplama</h1>
        <p>Farklı KDV hesaplama yöntemleriyle çalışın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubuu">
          <label>
            Hesaplama Türü
            <select
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              className="tarih-input"
            >
              <option value="dahilden">KDV Dahil → KDV Hariç</option>
              <option value="haricten">KDV Hariç → KDV Dahil</option>
              <option value="matrah">KDV Tutarından Matrah Bulma</option>
            </select>
          </label>
        </div>

        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              {mode === 'matrah' ? 'KDV Tutarı (TL)' : 'Tutar (TL)'}
              <input
                type="number"
                value={tutar}
                onChange={(e) => setTutar(e.target.value)}
                min="0.01"
                step="0.01"
                className="tarih-input"
                placeholder="Ör: 1000"
              />
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              KDV Oranı (%)
              <input
                type="number"
                value={kdvOrani}
                onChange={(e) => setKdvOrani(e.target.value)}
                min="1"
                max="100"
                className="tarih-input"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-paneli">
          <h2>Hesaplama Sonuçları</h2>
          <div className="sonuc-grid">
            {mode === 'dahilden' && (
              <>
                <div className="sonuc-satir">
                  <span>KDV Hariç Tutar:</span>
                  <span>{formatTL(sonuc)}</span>
                </div>
                <div className="sonuc-satir">
                  <span>KDV Tutarı:</span>
                  <span>{formatTL(tutar - sonuc)}</span>
                </div>
              </>
            )}
            
            {mode === 'haricten' && (
              <>
                <div className="sonuc-satir">
                  <span>KDV Dahil Tutar:</span>
                  <span>{formatTL(sonuc)}</span>
                </div>
                <div className="sonuc-satir">
                  <span>KDV Tutarı:</span>
                  <span>{formatTL(sonuc - tutar)}</span>
                </div>
              </>
            )}

            {mode === 'matrah' && (
              <div className="sonuc-satir">
                <span>Matrah Tutarı:</span>
                <span>{formatTL(sonuc)}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default KDVHesaplama;