import React, { useState } from 'react';
import './CevreHesaplama.css';

const CevreHesaplama = () => {
  const [sekil, setSekil] = useState('daire');
  const [olculer, setOlculer] = useState({});
  const [cevre, setCevre] = useState(null);
  const [hata, setHata] = useState('');

  const sekilConfig = {
    daire: { inputs: ['yaricap'], label: 'Yarıçap' },
    'eskenar-ucgen': { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    'ikizkenar-ucgen': { inputs: ['taban', 'yanKenar'], label: ['Taban', 'Yan Kenar'] },
    'cesitkenar-ucgen': { inputs: ['kenar1', 'kenar2', 'kenar3'], label: ['Kenar 1', 'Kenar 2', 'Kenar 3'] },
    dikdortgen: { inputs: ['kisaKenar', 'uzunKenar'], label: ['Kısa Kenar', 'Uzun Kenar'] },
    kare: { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    'eskenar-dortgen': { inputs: ['kenar', 'aci'], label: ['Kenar', 'Açı (º)'] },
    paralelkenar: { inputs: ['kenarA', 'kenarB'], label: ['Kenar A', 'Kenar B'] },
    'ikizkenar-yamuk': { inputs: ['ustTaban', 'altTaban', 'yanKenar'], label: ['Üst Taban', 'Alt Taban', 'Yan Kenar'] },
    'cesitkenar-yamuk': { inputs: ['kenar1', 'kenar2', 'kenar3', 'kenar4'], label: ['Kenar 1', 'Kenar 2', 'Kenar 3', 'Kenar 4'] },
    'duzgun-besgen': { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    'duzgun-altigen': { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    'duzgun-yedigen': { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    'duzgun-sekizgen': { inputs: ['kenar'], label: 'Kenar Uzunluğu' },
    elips: { inputs: ['buyukEksen', 'kucukEksen'], label: ['Büyük Eksen', 'Küçük Eksen'] }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'sekil') {
      setSekil(value);
      setOlculer({});
      setCevre(null);
    } else {
      setOlculer({
        ...olculer,
        [name]: parseFloat(value)
      });
    }
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    // Validasyon
    const requiredFields = sekilConfig[sekil].inputs;
    if (requiredFields.some(field => !olculer[field] || olculer[field] <= 0)) {
      setHata('Lütfen tüm geçerli ölçüleri girin!');
      return;
    }

    // Çevre Hesaplama
    let perimeter;
    const { inputs } = sekilConfig[sekil];
    
    switch(sekil) {
      case 'daire':
        perimeter = 2 * Math.PI * olculer.yaricap;
        break;
      case 'eskenar-ucgen':
        perimeter = 3 * olculer.kenar;
        break;
      case 'ikizkenar-ucgen':
        perimeter = olculer.taban + 2 * olculer.yanKenar;
        break;
      case 'cesitkenar-ucgen':
        perimeter = olculer.kenar1 + olculer.kenar2 + olculer.kenar3;
        break;
      case 'dikdortgen':
        perimeter = 2 * (olculer.kisaKenar + olculer.uzunKenar);
        break;
      case 'kare':
        perimeter = 4 * olculer.kenar;
        break;
      case 'eskenar-dortgen':
        perimeter = 4 * olculer.kenar;
        break;
      case 'paralelkenar':
        perimeter = 2 * (olculer.kenarA + olculer.kenarB);
        break;
      case 'ikizkenar-yamuk':
        perimeter = olculer.ustTaban + olculer.altTaban + 2 * olculer.yanKenar;
        break;
      case 'cesitkenar-yamuk':
        perimeter = olculer.kenar1 + olculer.kenar2 + olculer.kenar3 + olculer.kenar4;
        break;
      case 'duzgun-besgen':
        perimeter = 5 * olculer.kenar;
        break;
      case 'duzgun-altigen':
        perimeter = 6 * olculer.kenar;
        break;
      case 'duzgun-yedigen':
        perimeter = 7 * olculer.kenar;
        break;
      case 'duzgun-sekizgen':
        perimeter = 8 * olculer.kenar;
        break;
      case 'elips':
        // Yaklaşık çevre formülü (Ramanujan)
        const a = olculer.buyukEksen/2;
        const b = olculer.kucukEksen/2;
        const h = Math.pow((a - b)/(a + b), 2);
        perimeter = Math.PI * (a + b) * (1 + (3*h)/(10 + Math.sqrt(4 - 3*h)));
        break;
      default:
        perimeter = 0;
    }

    setCevre(perimeter.toFixed(2));
  };

  const temizle = () => {
    setSekil('daire');
    setOlculer({});
    setCevre(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container cevre">
      <div className="baslik-bolumu">
        <h1>📏 Geometrik Çevre Hesaplama</h1>
        <p>Şekil seçerek çevre uzunluğunu hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div>
          <label>
            Şekil Seçin
            <select 
              name="sekil" 
              value={sekil} 
              onChange={handleChange}
              className="tarih-input"
            >
              <option value="daire">Daire</option>
              <option value="eskenar-ucgen">Eşkenar Üçgen</option>
              <option value="ikizkenar-ucgen">İkizkenar Üçgen</option>
              <option value="cesitkenar-ucgen">Çeşitkenar Üçgen</option>
              <option value="dikdortgen">Dikdörtgen</option>
              <option value="kare">Kare</option>
              <option value="eskenar-dortgen">Eşkenar Dörtgen</option>
              <option value="paralelkenar">Paralelkenar</option>
              <option value="ikizkenar-yamuk">İkizkenar Yamuk</option>
              <option value="cesitkenar-yamuk">Çeşitkenar Yamuk</option>
              <option value="duzgun-besgen">Düzgün Beşgen</option>
              <option value="duzgun-altigen">Düzgün Altıgen</option>
              <option value="duzgun-yedigen">Düzgün Yedigen</option>
              <option value="duzgun-sekizgen">Düzgün Sekizgen</option>
              <option value="elips">Elips</option>
            </select>
          </label>
        </div>

        <div>
          {sekilConfig[sekil].inputs.map((input, index) => (
            <div>
              <label>
                {Array.isArray(sekilConfig[sekil].label) 
                  ? sekilConfig[sekil].label[index] 
                  : sekilConfig[sekil].label}
                <input
                  type="number"
                  name={input}
                  value={olculer[input] || ''}
                  onChange={handleChange}
                  min="0.1"
                  step="0.1"
                  className="tarih-input"
                  placeholder={`Ör: ${index + 1 * 5}`}
                  required
                />
              </label>
            </div>
          ))}
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {cevre && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Çevre</h2>
          <div className="cevre-sonuc">
            <span className="cevre-deger">{cevre}</span>
            <span className="cevre-birim">birim</span>
          </div>
          <p className="uyari">
            * Sonuçlar 2 ondalık basamağa yuvarlanmıştır
            {sekil === 'elips' && ' (Ramanujan yaklaşık formülü kullanıldı)'}
          </p>
        </div>
      )}
    </div>
  );
};

export default CevreHesaplama;