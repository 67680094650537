import React, { useState } from 'react';
import './takdirtesekkurhesaplama.css';

const TakdirTesekkur = () => {
  const DERSLER = [
    { ad: 'Beden Eğitimi', saat: 2 },
    { ad: 'Din Kültürü', saat: 2 },
    { ad: 'Fen Bilimleri', saat: 4 },
    { ad: 'Görsel Sanatlar', saat: 1 },
    { ad: 'Matematik', saat: 5 },
    { ad: 'Müzik', saat: 1 },
    { ad: 'İnkılap Tarihi', saat: 2 },
    { ad: 'Tekno. ve Tasarım', saat: 2 },
    { ad: 'Türkçe', saat: 5 },
    { ad: 'Yabancı Dil', saat: 4 }
  ];

  const [notlar, setNotlar] = useState(
    DERSLER.reduce((acc, ders) => ({ ...acc, [ders.ad]: '' }), {}
  ));

  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (dersAdi, value) => {
    const temizDeger = value.replace(/,/g, '.');
    setNotlar(prev => ({
      ...prev,
      [dersAdi]: temizDeger
    }));
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');

    // Validasyon
    const gecersizNot = Object.entries(notlar).find(([ders, not]) => {
      const numNot = parseFloat(not) || 0;
      return numNot < 0 || numNot > 100;
    });

    if(gecersizNot) {
      setHata(`${gecersizNot[0]} dersinde geçersiz not! (0-100 arası olmalı)`);
      setSonuc(null);
      return;
    }

    // Hesaplama
    const toplamSaat = DERSLER.reduce((acc, ders) => acc + ders.saat, 0);
    const toplamPuan = DERSLER.reduce((acc, ders) => {
      const not = parseFloat(notlar[ders.ad]) || 0;
      return acc + (not * ders.saat);
    }, 0);

    const ortalama = toplamPuan / toplamSaat;
    const belge = ortalama >= 85 ? 'Takdir Belgesi' :
                 ortalama >= 70 ? 'Teşekkür Belgesi' : 
                 'Belge Alamıyorsunuz';

    setSonuc({
      ortalama: ortalama.toFixed(2),
      belge,
      renk: ortalama >= 85 ? '#4CAF50' : 
            ortalama >= 70 ? '#2196F3' : 
            '#f44336'
    });
  };

  const temizle = () => {
    setNotlar(DERSLER.reduce((acc, ders) => ({ 
      ...acc, 
      [ders.ad]: '' 
    }), {}));
    setSonuc(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container takdir">
      <div className="baslik-bolumu">
        <h1>Takdir-Teşekkür Hesaplama</h1>
        <p>Tüm ders notlarınızı girin (Virgül yerine nokta kullanabilirsiniz)</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="ders-grid">
          {DERSLER.map(ders => (
            <div key={ders.ad} className="ders-karti">
              <div className="ders-baslik">
                <h3>{ders.ad}</h3>
                <span className="saat">{ders.saat} Saat</span>
              </div>
              <input
                type="text"
                inputMode="decimal"
                placeholder="0-100 arası"
                value={notlar[ders.ad]}
                onChange={(e) => handleChange(ders.ad, e.target.value)}
                className="not-input"
              />
            </div>
          ))}
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-paneli" style={{ borderColor: sonuc.renk }}>
          <h2>Sonuç</h2>
          <div className="ortalama">
            Dönem Ortalamanız: 
            <span style={{ color: sonuc.renk }}>{sonuc.ortalama}</span>
          </div>
          <div className="belge" style={{ color: sonuc.renk }}>
            {sonuc.belge}
          </div>
        </div>
      )}
    </div>
  );
};

export default TakdirTesekkur;