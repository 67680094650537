import React, { useState } from 'react';
import './EhliyetPuanHesaplama.css';

const EhliyetSınavı = () => {
  const [dogruCevaplar, setDogruCevaplar] = useState('');
  const [puan, setPuan] = useState(null);
  const [mesaj, setMesaj] = useState('');

  const handleHesapla = (e) => {
    e.preventDefault();
    const dogru = parseInt(dogruCevaplar);

    if (isNaN(dogru) || dogru < 0 || dogru > 50) {
      setMesaj('Lütfen 0 ile 50 arasında geçerli bir doğru cevap sayısı girin.');
      return;
    }

    const hesaplananPuan = dogru * 2; // 50 soru, her biri 2 puan

    setPuan(hesaplananPuan);
    if (hesaplananPuan >= 70) {
      setMesaj('Tebrikler! Sınavdan geçtiniz.');
    } else {
      setMesaj('Maalesef sınavdan kaldınız.');
    }
  };

  const handleTemizle = () => {
    setDogruCevaplar('');
    setPuan(null);
    setMesaj('');
  };

  return (
    <div className="ehliyet-container">
      <h1>Ehliyet Sınavı Puan Hesaplama</h1>
      <form onSubmit={handleHesapla}>
        <label>
          * Doğru Cevap Sayısı (0-50):
          <input
            type="number"
            value={dogruCevaplar}
            onChange={(e) => setDogruCevaplar(e.target.value)}
            placeholder="Örn: 45"
          />
        </label>
        <button type="submit" className="hesapla-button">Hesapla</button>
        <button type="button" className="temizle-button" onClick={handleTemizle}>Temizle</button>
      </form>

      {puan !== null && (
        <div className="sonuc-container">
          <p><strong>Hesaplanan Puan:</strong> {puan}</p>
          <div className="progress-bar">
            <div
              className={`progress-fill ${puan >= 70 ? 'pass' : 'fail'}`}
              style={{ width: `${(puan / 100) * 100}%` }}
            >
              {puan}
            </div>
          </div>
          <p>{mesaj}</p>
        </div>
      )}
    </div>
  );
};

export default EhliyetSınavı;
