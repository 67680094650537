import React, { useState } from 'react';
import './EbobEkokHesaplama.css';

const EbobEkokHesaplama = () => {
  const [sayilar, setSayilar] = useState({ sayi1: '', sayi2: '' });
  const [sonuc, setSonuc] = useState({ ebob: null, ekok: null });
  const [hata, setHata] = useState('');

  const ebobHesapla = (a, b) => {
    while(b !== 0) {
      let temp = b;
      b = a % b;
      a = temp;
    }
    return a;
  };

  const ekokHesapla = (a, b) => {
    return (a * b) / ebobHesapla(a, b);
  };

  const handleChange = (e) => {
    setSayilar({
      ...sayilar,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    const num1 = parseInt(sayilar.sayi1);
    const num2 = parseInt(sayilar.sayi2);

    if (!num1 || !num2 || num1 < 1 || num2 < 1) {
      setHata('Lütfen 1\'den büyük iki sayı girin!');
      setSonuc({ ebob: null, ekok: null });
      return;
    }

    const ebob = ebobHesapla(num1, num2);
    const ekok = ekokHesapla(num1, num2);
    
    setSonuc({ ebob, ekok });
  };

  const temizle = () => {
    setSayilar({ sayi1: '', sayi2: '' });
    setSonuc({ ebob: null, ekok: null });
    setHata('');
  };

  return (
    <div className="hesaplama-container ebob-ekok">
      <div className="baslik-bolumu">
        <h1>EBOB & EKOK Hesaplama</h1>
        <p>İki pozitif tam sayı için hesaplama yapın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              1. Sayı
              <input
                type="number"
                name="sayi1"
                value={sayilar.sayi1}
                onChange={handleChange}
                min="1"
                className="tarih-input"
                placeholder="Ör: 24"
              />
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              2. Sayı
              <input
                type="number"
                name="sayi2"
                value={sayilar.sayi2}
                onChange={handleChange}
                min="1"
                className="tarih-input"
                placeholder="Ör: 36"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {(sonuc.ebob !== null || sonuc.ekok !== null) && (
        <div className="sonuc-paneli">
          <div className="sonuc-grid">
            <div className="sonuc-kutu">
              <div className="sonuc-label">EBOB</div>
              <div className="sonuc-deger">{sonuc.ebob}</div>
            </div>
            <div className="sonuc-kutu">
              <div className="sonuc-label">EKOK</div>
              <div className="sonuc-deger">{sonuc.ekok}</div>
            </div>
          </div>
          <p className="uyari">
            * EBOB: En Büyük Ortak Bölen
            <br />
            * EKOK: En Küçük Ortak Kat
          </p>
        </div>
      )}
    </div>
  );
};

export default EbobEkokHesaplama;