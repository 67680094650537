import React, { useState } from 'react';
import './MesafeHesaplama.css';

const MesafeHesaplama = () => {
  const [baslangic, setBaslangic] = useState('');
  const [varis, setVaris] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [yukleniyor, setYukleniyor] = useState(false);
  const [hata, setHata] = useState('');

  const API_KEY = '5b3ce3597851110001cf624898d6ff1311c84ed6a1168f1db54a6f9e';

  const koordinatBul = async (adres) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(adres)}&countrycodes=tr`
    );
    const data = await response.json();
    return data[0] ? [data[0].lon, data[0].lat] : null;
  };

  const mesafeHesapla = async (e) => {
    e.preventDefault();
    setHata('');
    
    if(!baslangic || !varis) {
      setHata('Lütfen her iki konumu da girin!');
      return;
    }

    try {
      setYukleniyor(true);
      
      const baslangicKoordinat = await koordinatBul(baslangic);
      const varisKoordinat = await koordinatBul(varis);

      if(!baslangicKoordinat || !varisKoordinat) {
        throw new Error('Konum bulunamadı!');
      }

      const response = await fetch(
        `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${API_KEY}&start=${baslangicKoordinat}&end=${varisKoordinat}`
      );
      
      const data = await response.json();
      
      setSonuc({
        mesafe: (data.features[0].properties.segments[0].distance / 1000).toFixed(1),
        sure: Math.floor(data.features[0].properties.segments[0].duration / 60)
      });

    } catch (error) {
      setHata(error.message || 'Hesaplama başarısız oldu');
    } finally {
      setYukleniyor(false);
    }
  };

  return (
    <div className="mesafe-container">
      <h1>🇹🇷 Türkiye Mesafe Hesaplama</h1>
      
      <form onSubmit={mesafeHesapla}>
        <div className="input-grubu">
          <input
            type="text"
            placeholder="Başlangıç (Örn: İstanbul, Taksim)"
            value={baslangic}
            onChange={(e) => setBaslangic(e.target.value)}
          />
          <input
            type="text"
            placeholder="Varış (Örn: Ankara, Kızılay)"
            value={varis}
            onChange={(e) => setVaris(e.target.value)}
          />
        </div>

        <button type="submit" disabled={yukleniyor}>
          {yukleniyor ? 'Hesaplanıyor...' : 'Hesapla'}
        </button>

        {hata && <div className="hata">{hata}</div>}
      </form>

      {sonuc && (
        <div className="sonuc-kutusu">
          <div className="sonuc-item">
            <span>Mesafe:</span>
            <span className="vurgu">{sonuc.mesafe} km</span>
          </div>
          <div className="sonuc-item">
            <span>Tahmini Süre:</span>
            <span className="vurgu">{sonuc.sure} dakika</span>
          </div>
        </div>
      )}

      <div className="bilgilendirme">
        <p>* Sonuçlar karayolu için tahmini değerlerdir</p>
        <p>* Trafik durumuna göre değişiklik gösterebilir</p>
      </div>
    </div>
  );
};

export default MesafeHesaplama;