import React, { useState } from 'react';
import './IdealKiloHesaplama.css';

const IdealKiloHesaplama = () => {
  const [inputs, setInputs] = useState({
    gender: 'male',
    height: ''
  });
  const [idealKilo, setIdealKilo] = useState({ min: null, max: null });
  const [hata, setHata] = useState('');

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setIdealKilo({ min: null, max: null });

    if (!inputs.height) {
      setHata('Lütfen boyunuzu girin!');
      return;
    }

    const height = parseInt(inputs.height);
    
    if (height < 100 || height > 250) {
      setHata('Geçerli bir boy değeri girin (100-250 cm)');
      return;
    }

    // Robinson Formülü (1993)
    let baseWeight;
    if(inputs.gender === 'male') {
      baseWeight = 52 + 1.9 * (height / 2.54 - 60);
    } else {
      baseWeight = 49 + 1.7 * (height / 2.54 - 60);
    }

    const min = Math.round(baseWeight * 0.95);
    const max = Math.round(baseWeight * 1.05);
    
    setIdealKilo({ min, max });
  };

  const temizle = () => {
    setInputs({
      gender: 'male',
      height: ''
    });
    setIdealKilo({ min: null, max: null });
    setHata('');
  };

  return (
    <div className="hesaplama-container ideal-kilo">
      <div className="baslik-bolumu">
        <h1>İdeal Kilo Hesaplama</h1>
        <p>Boyunuza göre sağlıklı kilo aralığınızı öğrenin</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-gridd">
          <div className="input-grubbu">
            <label>
              Cinsiyet
              <select
                name="gender"
                value={inputs.gender}
                onChange={handleChange}
                className="tarih-input"
              >
                <option value="male">Erkek</option>
                <option value="female">Kadın</option>
              </select>
            </label>
          </div>

          <div className="input-grubbu">
            <label>
              Boy (cm)
              <input
                type="number"
                name="height"
                value={inputs.height}
                onChange={handleChange}
                min="100"
                max="250"
                className="tarih-input"
                placeholder="Ör: 175"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {idealKilo.min && (
        <div className="sonuc-paneli">
          <h2>İdeal Kilo Aralığınız</h2>
          <div className="kilo-aralik">
            <div className="kilo-kutu">
              <div className="kilo-deger">{idealKilo.min}</div>
              <div className="kilo-birim">Minimum kg</div>
            </div>
            <div className="aralik-ok">→</div>
            <div className="kilo-kutu">
              <div className="kilo-deger">{idealKilo.max}</div>
              <div className="kilo-birim">Maksimum kg</div>
            </div>
          </div>
          <p className="uyari">
            * Robinson formülüne göre hesaplanmıştır. 
            <br />
            Sağlıklı kilo aralığı ±%5 tolerans içerir.
          </p>
        </div>
      )}
    </div>
  );
};

export default IdealKiloHesaplama;