import React, { useState } from 'react';
import './FaktoriyelHesaplama.css';

const FaktoriyelHesaplama = () => {
  const [sayi, setSayi] = useState('');
  const [sonuc, setSonuc] = useState('');
  const [hata, setHata] = useState('');
  const [hesaplanıyor, setHesaplanıyor] = useState(false);

  const faktoriyelHesapla = (n) => {
    if (n === 0 || n === 1) return (1);
    return (n) * faktoriyelHesapla(n - 1);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === '' || (value.match(/^[0-9]+$/) && parseInt(value) <= 500)) {
      setSayi(value);
    }
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setSonuc('');

    if (!sayi) {
      setHata('Lütfen bir sayı girin!');
      return;
    }

    const num = parseInt(sayi);
    
    if (num < 0) {
      setHata('Negatif sayıların faktöriyeli hesaplanamaz!');
      return;
    }

    if (num > 500) {
      setHata('500\'den büyük sayılar desteklenmiyor!');
      return;
    }

    setHesaplanıyor(true);
    
    // Büyük hesaplamalar için asenkron işlem
    setTimeout(() => {
      try {
        const result = faktoriyelHesapla(num).toString();
        setSonuc(result);
      } catch (error) {
        setHata('Hesaplama sınırı aşıldı!');
      }
      setHesaplanıyor(false);
    }, 0);
  };

  const temizle = () => {
    setSayi('');
    setSonuc('');
    setHata('');
  };

  return (
    <div className="hesaplama-container faktoriyel">
      <div className="baslik-bolumu">
        <h1>Faktöriyel Hesaplama</h1>
        <p>0-500 arası tam sayılar için faktöriyel hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubuu">
          <label>
            Sayı
            <input
              type="number"
              value={sayi}
              onChange={handleChange}
              min="0"
              max="500"
              className="tarih-input"
              placeholder="Ör: 25"
            />
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button 
            type="submit" 
            className="hesapla-btn"
            disabled={hesaplanıyor}
          >
            {hesaplanıyor ? 'Hesaplanıyor...' : 'Hesapla'}
          </button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-paneli">
          <h2>{sayi}! Sonucu</h2>
          <div className="sonuc-metin">
            <div className="sonuc-ust">
              <span className="sonuc-sayi">{sayi}! =</span>
              <span className="sonuc-deger">{sonuc}</span>
            </div>
            <p className="karakter-sayisi">
              {sonuc.length} rakamdan oluşur
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaktoriyelHesaplama;