import React, { useState } from 'react';
import './vizefinalhesaplama.css';

const VizeFinalHesaplama = () => {
  const [vizeSayisi, setVizeSayisi] = useState(1);
  const [vizeler, setVizeler] = useState([{ not: '', yuzde: '' }]);
  const [final, setFinal] = useState({ not: '', yuzde: '' });
  const [ortalama, setOrtalama] = useState(null);
  const [hata, setHata] = useState('');

  // Vize sayısı değiştiğinde
  const handleVizeSayisiChange = (sayi) => {
    setVizeSayisi(sayi);
    setVizeler(Array(sayi).fill().map((_, i) => vizeler[i] || { not: '', yuzde: '' }));
  };

  // Vize bilgilerini güncelle
  const handleVizeChange = (index, field, value) => {
    const yeniVizeler = [...vizeler];
    yeniVizeler[index][field] = value.replace(',', '.');
    setVizeler(yeniVizeler);
  };

  // Final bilgilerini güncelle
  const handleFinalChange = (field, value) => {
    setFinal(prev => ({
      ...prev,
      [field]: value.replace(',', '.')
    }));
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');

    // Validasyonlar
    let toplamYuzde = vizeler.reduce((sum, vize) => sum + (parseFloat(vize.yuzde) || 0), 0);
    toplamYuzde += parseFloat(final.yuzde) || 0;

    if(toplamYuzde !== 100) {
      setHata('Yüzde oranları toplamı 100 olmalıdır!');
      return;
    }

    // Hesaplama
    let ortalama = vizeler.reduce((sum, vize) => {
      return sum + ((parseFloat(vize.not) || 0) * (parseFloat(vize.yuzde) || 0)) / 100;
    }, 0);

    ortalama += ((parseFloat(final.not) || 0) * (parseFloat(final.yuzde) || 0)) / 100;
    
    setOrtalama(ortalama.toFixed(2));
  };

  const temizle = () => {
    setVizeler(Array(vizeSayisi).fill().map(() => ({ not: '', yuzde: '' })));
    setFinal({ not: '', yuzde: '' });
    setOrtalama(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container vize-final">
      <div className="baslik-bolumu">
        <h1>Vize-Final Ortalama Hesaplama</h1>
        <p>Vize sayısını seçin ve notları girin</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="vize-sayisi-secim">
          <label>Vize Sayısı:</label>
          {[1, 2, 3].map((sayi) => (
            <button
              type="button"
              key={sayi}
              className={`vize-sayisi-btn ${vizeSayisi === sayi ? 'active' : ''}`}
              onClick={() => handleVizeSayisiChange(sayi)}
            >
              {sayi}
            </button>
          ))}
        </div>

        <div className="not-grid">
          {/* Vizeler */}
          {vizeler.map((vize, index) => (
            <div key={index} className="not-grubu">
              <h3>{index + 1}. Vize</h3>
              <input
                type="text"
                inputMode="decimal"
                placeholder="Not (0-100)"
                value={vize.not}
                onChange={(e) => handleVizeChange(index, 'not', e.target.value)}
              />
              <input
                type="text"
                inputMode="decimal"
                placeholder="Yüzde (%)"
                value={vize.yuzde}
                onChange={(e) => handleVizeChange(index, 'yuzde', e.target.value)}
              />
            </div>
          ))}

          {/* Final */}
          <div className="not-grubu final">
            <h3>Final</h3>
            <input
              type="text"
              inputMode="decimal"
              placeholder="Not (0-100)"
              value={final.not}
              onChange={(e) => handleFinalChange('not', e.target.value)}
            />
            <input
              type="text"
              inputMode="decimal"
              placeholder="Yüzde (%)"
              value={final.yuzde}
              onChange={(e) => handleFinalChange('yuzde', e.target.value)}
            />
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {ortalama && (
        <div className="sonuc-paneli">
          <h2>Not Ortalamanız</h2>
          <div className="ortalama-sonuc">{ortalama}</div>
          <div className="sonuc-aciklama">
           
          </div>
        </div>
      )}
    </div>
  );
};

export default VizeFinalHesaplama;