import React, { useState, useEffect } from 'react';
import './YilinKacinciGunu.css';

const GunHesaplama = () => {
  const [seciliTarih, setSeciliTarih] = useState('');
  const [gunSayisi, setGunSayisi] = useState(0);
  const [hata, setHata] = useState('');

  // Bugünün yılın kaçıncı günü olduğunu hesapla
  useEffect(() => {
    const bugun = new Date();
    const yilinIlkGunu = new Date(bugun.getFullYear(), 0, 1);
    const fark = bugun - yilinIlkGunu;
    const gun = Math.floor(fark / (1000 * 60 * 60 * 24)) + 1;
    setGunSayisi(gun);
  }, []);

  const tarihHesapla = (tarih) => {
    const secilenTarih = new Date(tarih);
    if(isNaN(secilenTarih)) {
      setHata('Geçersiz tarih formatı!');
      return 0;
    }
    
    const yil = secilenTarih.getFullYear();
    const yilinIlkGunu = new Date(yil, 0, 1);
    const fark = secilenTarih - yilinIlkGunu;
    return Math.floor(fark / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleChange = (e) => {
    setHata('');
    const tarih = e.target.value;
    setSeciliTarih(tarih);
    
    if(tarih) {
      const gun = tarihHesapla(tarih);
      if(gun > 0) setGunSayisi(gun);
    }
  };

  const temizle = () => {
    setSeciliTarih('');
    const bugun = new Date();
    const yilinIlkGunu = new Date(bugun.getFullYear(), 0, 1);
    const fark = bugun - yilinIlkGunu;
    setGunSayisi(Math.floor(fark / (1000 * 60 * 60 * 24)) + 1);
  };

  return (
    <div className="hesaplama-container gun-hesaplama">
      <div className="baslik-bolumu">
        <h1>📅 Yılın Kaçıncı Günü?</h1>
        <p>Seçilen tarihin yıl içindeki sırasını bulun</p>
      </div>

      <div className="input-grubu">
        <label>
          Tarih Seçin
          <input
            type="date"
            value={seciliTarih}
            onChange={handleChange}
            max={new Date().toISOString().split('T')[0]}
            className="tarih-input"
          />
        </label>
      </div>

      {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

      <div className="sonuc-paneli">
        <div className="gun-sonuc">
          <span className="sayi">{gunSayisi}</span>
          <span className="metin">. gün</span>
        </div>
        <p className="aciklama">
          {seciliTarih 
            ? `Seçtiğiniz tarih ${new Date(seciliTarih).toLocaleDateString('tr-TR')}`
            : "Bugünün tarihi"
          }
        </p>
      </div>

      <div className="aksiyon-bolum">
        <button className="temizle-btn" onClick={temizle}>
          Sıfırla
        </button>
      </div>
    </div>
  );
};

export default GunHesaplama;