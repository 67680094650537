import React, { useState } from 'react';
import './VadeliMevduat.css';

const VadeliMevduat = () => {
  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [term, setTerm] = useState('');
  const [taxRate, setTaxRate] = useState(15); // Stopaj oranı default %15
  const [results, setResults] = useState(null);

  const handleCalculate = () => {
    const P = parseFloat(principal.replace(',', '.'));
    const rate = parseFloat(interestRate.replace(',', '.'));
    const vade = parseInt(term);

    if (isNaN(P) || isNaN(rate) || isNaN(vade) || P <= 0 || rate <= 0 || vade <= 0) {
      setResults(null);
      return;
    }

    const grossInterest = (P * rate * vade) / (365 * 100);
    const tax = (grossInterest * taxRate) / 100;
    const netInterest = grossInterest - tax;
    const totalReturn = P + netInterest;
    const getiriOrani = (netInterest / P) * 100;

    setResults({
      principal: P.toFixed(2),
      grossInterest: grossInterest.toFixed(2),
      tax: tax.toFixed(2),
      netInterest: netInterest.toFixed(2),
      totalReturn: totalReturn.toFixed(2),
      getiriOrani: getiriOrani.toFixed(2),
    });
  };

  const handleClear = () => {
    setPrincipal('');
    setInterestRate('');
    setTerm('');
    setResults(null);
  };

  const formatNumber = (number) => {
    return parseFloat(number).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <div className="vadeli-container">
      <h1 className="vadeli-title">Vadeli Mevduat Faizi Hesaplama</h1>
      <p style={{ textAlign: 'center', marginBottom: '30px',fontSize: '18px', fontFamily:'Poppins' }}>
        Bu araç ile vadeli mevduat faiz getirinizi hesaplayabilirsiniz.
      </p>
      <form className="vadeli-form">
        <label>
          * Anapara (₺):
          <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
            placeholder="Örn: 100000"
          />
        </label>
        <label>
          * Yıllık Faiz Oranı (%):
          <input
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="Örn: 50"
          />
        </label>
        <label>
          * Vade (Gün):
          <input
            type="number"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            placeholder="Örn: 32"
          />
        </label>
        <label>
          Stopaj Oranı (%):
          <input
            type="number"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
            placeholder="Varsayılan: 15"
          />
        </label>

        <div className="button-group">
          <button type="button" className="calc-button" onClick={handleCalculate}>
            Hesapla
          </button>
          <button type="button" className="clear-button" onClick={handleClear}>
            Temizle
          </button>
        </div>
      </form>

      {results && (
        <div className="result-card">
          <h2>Hesaplama Sonuçları</h2>
          <p><strong>Anapara:</strong> {formatNumber(results.principal)} ₺</p>
          <p><strong>Brüt Faiz:</strong> {formatNumber(results.grossInterest)} ₺</p>
          <p><strong>Stopaj (Vergi):</strong> {formatNumber(results.tax)} ₺</p>
          <p><strong>Net Faiz:</strong> {formatNumber(results.netInterest)} ₺</p>
          <p><strong>Getiri Oranı:</strong> %{results.getiriOrani}</p>
          <p><strong>Vade Sonu Toplam:</strong> {formatNumber(results.totalReturn)} ₺</p>
        </div>
      )}
    </div>
  );
};

export default VadeliMevduat;
