import React, { useState } from 'react';
import './KarOraniHesaplama.css';

const KarOraniHesaplama = () => {
  const [alisFiyati, setAlisFiyati] = useState('');
  const [satisFiyati, setSatisFiyati] = useState('');
  const [karOrani, setKarOrani] = useState(null);
  const [hata, setHata] = useState('');

  const hesaplaKarOrani = (e) => {
    e.preventDefault();
    setHata('');
    setKarOrani(null);

    if (!alisFiyati || !satisFiyati) {
      setHata('Lütfen alış ve satış fiyatlarını girin.');
      return;
    }

    const alis = parseFloat(alisFiyati);
    const satis = parseFloat(satisFiyati);

    if (alis <= 0 || satis <= 0) {
      setHata('Fiyatlar sıfırdan büyük olmalıdır.');
      return;
    }

    const kar = satis - alis;
    const oran = (kar / alis) * 100;
    setKarOrani(oran.toFixed(2));
  };

  return (
    <div className="kar-container">
      <div className="baslik">
        <h1>📈 Kar Oranı Hesaplama</h1>
        <p>Alış ve satış fiyatlarını girerek kar oranını hesaplayın.</p>
      </div>

      <form onSubmit={hesaplaKarOrani}>
        <div className="inpput-grid">
          <div className="inpput-grubu">
            <label>Alış Fiyatı (TL)</label>
            <input
              type="number"
              value={alisFiyati}
              onChange={(e) => setAlisFiyati(e.target.value)}
              min="0.01"
              step="0.01"
              required
            />
          </div>
          <div className="inpput-grubu">
            <label>Satış Fiyatı (TL)</label>
            <input
              type="number"
              value={satisFiyati}
              onChange={(e) => setSatisFiyati(e.target.value)}
              min="0.01"
              step="0.01"
              required
            />
          </div>
        </div>

        {hata && <div className="hata">{hata}</div>}

        <button type="submit" className="hesapla-btn">
          Hesapla
        </button>
      </form>

      {karOrani !== null && (
        <div className="sonuc">
          <h2>Sonuç:</h2>
          <div className="sonuc-deger">
            Kar Oranı: <span>{karOrani}%</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default KarOraniHesaplama;