import React, { useState } from 'react';
import './TaksiHesaplama.css';

const TaksiHesaplama = () => {
  const [il, setIl] = useState('istanbul');
  const [km, setKm] = useState('');
  const [ucret, setUcret] = useState(0);
  const [hata, setHata] = useState('');

  const ilTarifeleri = {
    istanbul: { acilis: 42, kmUcret: 28, minUcret: 135 },
    ankara: { acilis: 35, kmUcret: 25, minUcret: 100 },
    izmir: { acilis: 20, kmUcret: 30, minUcret: 135 },
    bursa: { acilis: 36, kmUcret: 36, minUcret: 115 },
    antalya: { acilis: 25, kmUcret: 30.00, minUcret: 90 },
    // Diğer iller eklenebilir
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    if(!km || km <= 0) {
      setHata('Lütfen geçerli km bilgisi girin!');
      return;
    }

    const tarife = ilTarifeleri[il];
    const hesaplanan = tarife.acilis + (km * tarife.kmUcret);
    setUcret(Math.max(hesaplanan, tarife.minUcret));
  };

  const formatTL = (tutar) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY'
    }).format(tutar);
  };

  return (
    <div className="taksi-container">
      <div className="baslik">
        <h1>🚖 İl Bazlı Taksi Ücreti Hesaplama</h1>
        <p>İl seçerek taksi ücretini hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div>
          <div className="input-grubuu">
            <label>
              İl Seçin
              <select 
                value={il} 
                onChange={(e) => setIl(e.target.value)}
                className="il-select"
              >
                <option value="istanbul">İstanbul</option>
                <option value="ankara">Ankara</option>
                <option value="izmir">İzmir</option>
                <option value="bursa">Bursa</option>
                <option value="antalya">Antalya</option>
              </select>
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              Mesafe (km)
              <input
                type="number"
                value={km}
                onChange={(e) => setKm(e.target.value)}
                min="0.1"
                step="0.1"
                placeholder="Ör: 5.5"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <button type="submit" className="hesapla-btn">
          Hesapla
        </button>
      </form>

      {ucret > 0 && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Ücret</h2>
          <div className="ucret-detay">
            <div className="ucret-item">
              <span>Toplam Ücret:</span>
              <span className="ucret">{formatTL(ucret)}</span>
            </div>
            <div className="tarife-bilgisi">
              <p>Seçilen İl Tarifesi:</p>
              <ul>
                <li>Açılış Ücreti: {formatTL(ilTarifeleri[il].acilis)}</li>
                <li>Kilometre Ücreti: {formatTL(ilTarifeleri[il].kmUcret)}/km</li>
                <li>Minimum Ücret: {formatTL(ilTarifeleri[il].minUcret)}</li>
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="bilgilendirme">
        <p>* Ücretler 2023 taksi tarifelerine göre hesaplanmıştır</p>
        <p>* Gerçek ücretlerden farklılık gösterebilir</p>
      </div>
    </div>
  );
};

export default TaksiHesaplama;