import React, { useState } from 'react';
import './TarihFarkiHesaplama.css';

const TarihFarkiHesaplama = () => {
  const [baslangic, setBaslangic] = useState('');
  const [bitis, setBitis] = useState('');
  const [sonuc, setSonuc] = useState({ yil: 0, ay: 0, gun: 0, toplamGun: 0 });
  const [hata, setHata] = useState('');

  const tarihFarkiHesapla = (startDate, endDate) => {
    let start = new Date(startDate);
    let end = new Date(endDate);
    
    // Tarihleri ay bazında karşılaştırma
    let yilFark = end.getFullYear() - start.getFullYear();
    let ayFark = end.getMonth() - start.getMonth();
    let gunFark = end.getDate() - start.getDate();

    if (gunFark < 0) {
      const sonAyGun = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      gunFark += sonAyGun;
      ayFark--;
    }

    if (ayFark < 0) {
      ayFark += 12;
      yilFark--;
    }

    // Toplam gün hesaplama
    const zamanFark = Math.abs(end - start);
    const toplamGun = Math.floor(zamanFark / (1000 * 60 * 60 * 24));

    return {
      yil: yilFark,
      ay: ayFark,
      gun: gunFark,
      toplamGun
    };
  };

  const handleHesapla = (e) => {
    e.preventDefault();
    setHata('');

    if (!baslangic || !bitis) {
      setHata('Lütfen her iki tarihi de seçin!');
      return;
    }

    const baslangicTarih = new Date(baslangic);
    const bitisTarih = new Date(bitis);

    if (baslangicTarih > bitisTarih) {
      setHata('Başlangıç tarihi bitiş tarihinden sonra olamaz!');
      return;
    }

    const fark = tarihFarkiHesapla(baslangic, bitis);
    setSonuc(fark);
  };

  const temizle = () => {
    setBaslangic('');
    setBitis('');
    setSonuc({ yil: 0, ay: 0, gun: 0, toplamGun: 0 });
    setHata('');
  };

  return (
    <div className="hesaplama-container tarih-fark">
      <div className="baslik-bolumu">
        <h1>📅 İki Tarih Arası Hesaplama</h1>
        <p>Başlangıç ve bitiş tarihlerini seçerek farkı görün</p>
      </div>

      <form onSubmit={handleHesapla}>
        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              Başlangıç Tarihi
              <input
                type="date"
                value={baslangic}
                onChange={(e) => setBaslangic(e.target.value)}
                max={bitis || new Date().toISOString().split('T')[0]}
                className="tarih-input"
              />
            </label>
          </div>
          
          <div className="input-grubuu">
            <label>
              Bitiş Tarihi
              <input
                type="date"
                value={bitis}
                onChange={(e) => setBitis(e.target.value)}
                min={baslangic}
                max={new Date().toISOString().split('T')[0]}
                className="tarih-input"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {(sonuc.yil > 0 || sonuc.ay > 0 || sonuc.gun > 0) && (
        <div className="sonuc-paneli">
          <h2>Zaman Farkı</h2>
          <div className="zaman-grid">
            <div className="zaman-kutu">
              <div className="zaman-deger">{sonuc.yil}</div>
              <div className="zaman-birim">Yıl</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{sonuc.ay}</div>
              <div className="zaman-birim">Ay</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{sonuc.gun}</div>
              <div className="zaman-birim">Gün</div>
            </div>
          </div>
          <div className="toplam-gun">
            Toplam: <span>{sonuc.toplamGun}</span> gün
          </div>
        </div>
      )}
    </div>
  );
};

export default TarihFarkiHesaplama;