import React, { useState } from 'react';
import './liseortalamahesaplama.css';

const LiseOrtalama = () => {
  const DERSLER = [
    "Beden Eğitimi", "Biyoloji", "Coğrafya", "Çağdaş Türk ve Dünya Tarihi",
    "Dil ve Anlatım", "Din Kültürü", "Felsefe", "Fizik", "Geometri",
    "Görsel Sanatlar ve Müzik", "İkinci Yabancı Dil", "Kimya", "Mantık",
    "Matematik", "Mesleki Arapça", "Milli Güvenlik Bilgisi", "Osmanlı Türkçesi",
    "Psikoloji", "Sağlık Bilgisi", "Sosyoloji", "T.C.İnkılap Tarihi", "Tarih",
    "Temel Dini Bilgiler", "Trafik ve İlkyardım", "Türk Dili ve Edebiyatı",
    "Türk Edebiyatı", "Türkçe", "Yabancı Dil-1", "Yabancı Dil-2",
    "Seçmeli-1", "Seçmeli-2", "Seçmeli-3", "Seçmeli-4", "Seçmeli-5", "Seçmeli-6",
    "Diğer-1", "Diğer-2", "Diğer-3", "Diğer-4", "Diğer-5", "Diğer-6", "Diğer-7"
  ];

  const [dersSayisi, setDersSayisi] = useState(1);
  const [dersler, setDersler] = useState([{ ders: '', saat: '', not: '' }]);
  const [ortalama, setOrtalama] = useState(null);
  const [hata, setHata] = useState('');

  // Ders sayısı değişim handler'ı
  const handleDersSayisi = (sayi) => {
    const yeniDersler = Array(sayi).fill().map((_, i) => 
      dersler[i] || { ders: '', saat: '', not: '' }
    );
    setDersSayisi(sayi);
    setDersler(yeniDersler);
  };

  // Ders bilgisi güncelleme
  const handleDersDegisim = (index, field, value) => {
    const yeniDersler = [...dersler];
    yeniDersler[index][field] = value;
    setDersler(yeniDersler);
  };

  // Hesaplama fonksiyonu
  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setOrtalama(null);

    // Validasyon
    const invalidEntry = dersler.some(ders => {
      const saat = parseInt(ders.saat);
      const not = parseFloat(ders.not);
      return (
        !ders.ders ||
        isNaN(saat) || saat < 1 ||
        isNaN(not) || not < 0 || not > 100
      );
    });

    if(invalidEntry) {
      setHata('Lütfen tüm alanları doğru doldurun! (Ders seçimi zorunlu, Saat ≥1, Not 0-100)');
      return;
    }

    // Hesaplama
    const { totalPoints, totalHours } = dersler.reduce((acc, ders) => {
      const hours = parseInt(ders.saat);
      const grade = parseFloat(ders.not);
      return {
        totalPoints: acc.totalPoints + (grade * hours),
        totalHours: acc.totalHours + hours
      };
    }, { totalPoints: 0, totalHours: 0 });

    if(totalHours === 0) {
      setHata('Geçerli ders saati bulunamadı');
      return;
    }

    setOrtalama((totalPoints / totalHours).toFixed(2));
  };

  // Temizleme fonksiyonu
  const temizle = () => {
    setDersler(Array(dersSayisi).fill().map(() => ({ 
      ders: '', 
      saat: '', 
      not: '' 
    })));
    setOrtalama(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container lise">
      <div className="baslik-bolumu">
      <h1 className="lise-title">Lise Not Ortalaması Hesaplama</h1>

        <p>Ders bilgilerini eksiksiz doldurun</p>
      </div>

      <div className="ders-sayisi-secimm">
        <span>Ders Sayısı:</span>
        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(sayi => (
          <button
            key={sayi}
            type="button"
            className={`sayi-btn ${dersSayisi === sayi ? 'active' : ''}`}
            onClick={() => handleDersSayisi(sayi)}
          >
            {sayi}
          </button>
        ))}
      </div>

      <form onSubmit={hesapla}>
        <div className="ders-grid">
          {dersler.map((ders, index) => (
            <div key={index} className="ders-karti">
              <div className="ders-header">
                <h3>{index + 1}. Ders</h3>
              </div>
              
              <select
                value={ders.ders}
                onChange={(e) => handleDersDegisim(index, 'ders', e.target.value)}
                className="ders-select"
                required
              >
                <option value="">Ders Seçin</option>
                {DERSLER.map(ders => (
                  <option key={ders} value={ders}>{ders}</option>
                ))}
              </select>

              <input
                type="number"
                min="1"
                placeholder="Ders Saati"
                value={ders.saat}
                onChange={(e) => handleDersDegisim(index, 'saat', e.target.value)}
                required
              />

              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                placeholder="Ders Notu (0-100)"
                value={ders.not}
                onChange={(e) => handleDersDegisim(index, 'not', e.target.value)}
                required
              />
            </div>
          ))}
        </div>

        {hata && <div className="hata-mesaji">❌ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {ortalama && (
        <div className="sonuc-paneli">
          <h2>Dönem Ortalamanız</h2>
          <div className="ortalama-deger">
            {ortalama}
            <span className="birim">/100</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiseOrtalama;