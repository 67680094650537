import React, { useState } from 'react';
import './FaizHesaplama.css';
import { formatNumber } from './utils/format';


const FaizHesaplama = () => {
  const [anapara, setAnapara] = useState('');
  const [vade, setVade] = useState('');
  const [faizOrani, setFaizOrani] = useState('');
  const [vadeBirimi, setVadeBirimi] = useState('ay'); // Günlük, Aylık veya Yıllık
  const [sonuc, setSonuc] = useState(null);
  const [error, setError] = useState('');

  const handleHesapla = (e) => {
    e.preventDefault();
    setError(''); // Önceki hatayı temizle

    const anaPara = parseFloat(anapara);
    const vadeValue = parseInt(vade);
    const faiz = parseFloat(faizOrani);

    if (!anaPara || !vadeValue || !faiz || anaPara <= 0 || vadeValue <= 0 || faiz <= 0) {
      setError('Lütfen tüm alanları geçerli değerlerle doldurun!');
      return;
    }

    let faizTutar = 0;
    let getiriOrani = 0;
    let vadeSonuToplam = 0;

    // Faiz oranı yıllık olacak, vade birimi göre hesaplama yapılacak
    if (vadeBirimi === 'gun') {
      // Günlük faiz oranı hesaplama
      faizTutar = anaPara * (faiz / 100) * (vadeValue / 365);  // Yıllık faiz oranını günlük olarak hesapla
      getiriOrani = (faizTutar / anaPara) * 100;
      vadeSonuToplam = anaPara + faizTutar;
    } else if (vadeBirimi === 'ay') {
      // Aylık faiz oranı hesaplama
      faizTutar = anaPara * (faiz / 100) * (vadeValue / 12);  // Yıllık faiz oranını aylık olarak hesapla
      getiriOrani = (faizTutar / anaPara) * 100;
      vadeSonuToplam = anaPara + faizTutar;
    } else {
      // Yıllık faiz oranı hesaplama
      faizTutar = anaPara * (faiz / 100) * vadeValue;  // Yıllık faiz oranını doğrudan yıllık olarak hesapla
      getiriOrani = (faizTutar / anaPara) * 100;
      vadeSonuToplam = anaPara + faizTutar;
    }

    setSonuc({
      anapara: anaPara.toFixed(2),
      faizTutar: faizTutar.toFixed(2),
      getiriOrani: getiriOrani.toFixed(2),
      vadeSonuToplam: vadeSonuToplam.toFixed(2),
    });
  };

  const handleTemizle = () => {
    setAnapara('');
    setVade('');
    setFaizOrani('');
    setSonuc(null);
    setError('');
  };

  return (
    <div className="faiz-container">
      <h1 className="faiz-title">Faiz Hesaplama</h1>
      <form className="faiz-form" onSubmit={handleHesapla}>
        <label>
          * Ana Para:
          <input
            type="number"
            value={anapara}
            onChange={(e) => setAnapara(e.target.value)}
            placeholder="Örn: 100000"
          />
        </label>
        <label>
          * Vade:
          <input
            type="number"
            value={vade}
            onChange={(e) => setVade(e.target.value)}
            placeholder="Örn: 32"
          />
        </label>
        <label>
          * Faiz Oranı (%):
          <input
            type="number"
            step="0.01"
            value={faizOrani}
            onChange={(e) => setFaizOrani(e.target.value)}
            placeholder="Örn: 50"
          />
        </label>
        <label>
          * Vade Birimi:
          <select value={vadeBirimi} onChange={(e) => setVadeBirimi(e.target.value)}>
            <option value="gun">Günlük</option>
            <option value="ay">Aylık</option>
            <option value="yil">Yıllık</option>
          </select>
        </label>
        {error && <p className="error-message">{error}</p>}
        <div className="button-group">
          <button type="submit" className="hesapla-button">Hesapla</button>
          <button type="button" className="temizle-button" onClick={handleTemizle}>Temizle</button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-card">
          <h2>Sonuçlar</h2>
          <p><strong>Anapara:</strong> {formatNumber(sonuc.anapara)} TL</p>
          <p><strong>Faiz Tutarı:</strong> {formatNumber(sonuc.faizTutar)} TL</p>
          <p><strong>Getiri Oranı:</strong> %{formatNumber(sonuc.getiriOrani)}</p>
          <p><strong>Vade Sonu Toplam:</strong> {formatNumber(sonuc.vadeSonuToplam)} TL</p>
        </div>
      )}
    </div>
  );
};

export default FaizHesaplama;
