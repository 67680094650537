import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null); // Aktif kategori
  const [subMenuOpen, setSubMenuOpen] = useState(null); // Açılan alt menü

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubMenu = (category) => {
    setSubMenuOpen(subMenuOpen === category ? null : category); // Aynı kategoriye tıklanırsa kapanır
  };

  return (
    <nav className="nav">
      <h2 className="logo">KolayHesap.net</h2>
      <div className="navLinks">
        <div><a href="/">Ana Sayfa</a></div>
        <div className={isOpen ? 'open' : ''}>
          <a href="#" onClick={toggleMenu}>
            Hesaplamalar
            <span className="arrow">{isOpen ? '▲' : '▼'}</span>
          </a>
          {isOpen && (
            <div className="dropdown">
              {/* Kredi Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('kredi')}>Kredi Hesaplamaları ▼</a>
                {subMenuOpen === 'kredi' && (
                  <div className="subDropdown">
                    <div><a href="/kredi-hesaplama">Kredi Hesaplama</a></div>
                    <div><a href="/kredi-ihtiyac">İhtiyaç Kredisi Hesaplama</a></div>

                    <div><a href="/kredi-konut">Konut Kredisi Hesaplama</a></div>
                    <div><a href="/kredi-karti">Kredi Kartı Gecikme Faizi Hesaplama</a></div>
                    <div><a href="/kredi-tasit">Taşıt Kredisi Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Finansal Hesaplamalar */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('finansal')}>Finansal Hesaplamalar ▼</a>
                {subMenuOpen === 'finansal' && (
                  <div className="subDropdown">
                    <div><a href="/faiz-hesaplama">Faiz Hesaplama</a></div>
                    <div><a href="/iban-dogrulama">IBAN Doğrulama</a></div>
                    <div><a href="/vadeli-mevduat-faizi-hesaplama">Vadeli Mevduat Faizi Hesaplama</a></div>
                    <div><a href="/kar-orani-hesaplama">Kâr Oranı Hesaplama</a></div>

                  </div>
                )}
              </div>
              {/* Sınav Puanı Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('sinav')}>Sınav Puanı Hesaplamaları ▼</a>
                {subMenuOpen === 'sinav' && (
                  <div className="subDropdown">
                    <div><a href="/ales-puan-hesaplama">ALES Puan Hesaplama</a></div>
                    <div><a href="/ehliyet-puan-hesaplama">Ehliyet Sınavı Puan Hesaplama</a></div>
                    <div><a href="/lgs-puan-hesaplama">LGS Puan Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Eğitim Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('egitim')}>Eğitim Hesaplamaları ▼</a>
                {subMenuOpen === 'egitim' && (
                  <div className="subDropdown">
                    <div><a href="/takdir-tesekkur-hesaplama">Takdir Teşekkür Hesaplama</a></div>
                    <div><a href="/vize-final-hesaplama">Vize Final Ortalama Hesaplama</a></div>
                    <div><a href="/lise-ortalama-hesaplama">Lise Ortalama Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Sağlık Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('saglik')}>Sağlık Hesaplamaları ▼</a>
                {subMenuOpen === 'saglik' && (
                  <div className="subDropdown">
                    <div><a href="/dogum-tarihi-hesaplama">Doğum Tarihi Hesaplama</a></div>
                    <div><a href="/yas-hesaplama">Yaş Hesaplama</a></div>
                    <div><a href="/gunluk-kalori-hesaplama">Günlük Kalori İhtiyacı Hesaplama</a></div>
                    <div><a href="/bki-hesaplama">Beden Kitle İndeksi Hesaplama</a></div>
                    <div><a href="/ideal-kilo-hesaplama">İdeal Kilo Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Matematiksel Hesaplamalar */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('matematik')}>Matematiksel Hesaplamalar ▼</a>
                {subMenuOpen === 'matematik' && (
                  <div className="subDropdown">
                    <div><a href="/alan-hesaplama">Alan Hesaplama</a></div>
                    <div><a href="/cevre-hesaplama">Çevre Hesaplama</a></div>
                    <div><a href="/ebob-ekok-hesaplama">EBOB EKOK Hesaplama</a></div>
                    <div><a href="/faktoriyel-hesaplama">Faktoriyel Hesaplama</a></div>
                    <div><a href="/hacim-hesaplama">Hacim Hesaplama</a></div>
                    <div><a href="/koklu-sayi-hesaplama">Köklü Sayı Hesaplama</a></div>
                    <div><a href="/uslu-sayi-hesaplama">Üslü Sayı Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Zaman Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('zaman')}>Zaman Hesaplamaları ▼</a>
                {subMenuOpen === 'zaman' && (
                  <div className="subDropdown">
                    <div><a href="/iki-tarih-arasi-hesaplama">İki Tarih Arası Hesaplama</a></div>
                    <div><a href="/ne-kadar-zaman-kaldi">Ne Kadar Zaman Kaldı Hesaplama</a></div>
                    <div><a href="/safak-hesaplama">Şafak Hesaplama</a></div>
                    <div><a href="/yilin-kacinci-gunu">Yılın Kaçıncı Günü Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Vergi Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('vergi')}>Vergi Hesaplamaları ▼</a>
                {subMenuOpen === 'vergi' && (
                  <div className="subDropdown">
                    <div><a href="/gelir-vergisi-hesaplama">Gelir Vergisi Hesaplama</a></div>
                    <div><a href="/gumruk-vergisi-hesaplama">Gümrük Vergisi Hesaplama</a></div>
                    <div><a href="/kdv-hesaplama">KDV Hesaplama</a></div>
                  </div>
                )}
              </div>
              {/* Seyahat Hesaplamaları */}
              <div>
                <a href="#" onClick={() => toggleSubMenu('seyahat')}>Seyahat Hesaplamaları ▼</a>
                {subMenuOpen === 'seyahat' && (
                  <div className="subDropdown">
                    <div><a href="/mesafe-hesaplama">Mesafe Hesaplama</a></div>
                    <div><a href="/taksi-ucreti-hesaplama">Taksi Ücreti Hesaplama</a></div>
                    <div><a href="/yakit-tuketimi-hesaplama">Yakıt Tüketimi Hesaplama</a></div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div><a href="/login">Giriş Yap</a></div>
        <div><a href="/register">Kayıt Ol</a></div>
      </div>
    </nav>
  );
}

export default Navbar;
