import React, { useState } from 'react';
import './HacimHesaplama.css';

const HacimHesaplama = () => {
  const [sekil, setSekil] = useState('kup');
  const [olculer, setOlculer] = useState({});
  const [hacim, setHacim] = useState(null);
  const [hata, setHata] = useState('');

  const sekilConfig = {
    prizma: {
      inputs: ['uzunluk', 'genislik', 'yukseklik'],
      labels: ['Uzunluk', 'Genişlik', 'Yükseklik']
    },
    kup: {
      inputs: ['kenar'],
      labels: ['Kenar Uzunluğu']
    },
    kure: {
      inputs: ['yaricap'],
      labels: ['Yarıçap']
    },
    silindir: {
      inputs: ['yaricap', 'yukseklik'],
      labels: ['Yarıçap', 'Yükseklik']
    },
    koni: {
      inputs: ['yaricap', 'yukseklik'],
      labels: ['Yarıçap', 'Yükseklik']
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'sekil') {
      setSekil(value);
      setOlculer({});
      setHacim(null);
    } else {
      setOlculer({
        ...olculer,
        [name]: parseFloat(value)
      });
    }
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    // Validasyon
    const requiredFields = sekilConfig[sekil].inputs;
    if (requiredFields.some(field => !olculer[field] || olculer[field] <= 0)) {
      setHata('Lütfen tüm geçerli ölçüleri girin!');
      return;
    }

    // Hacim Hesaplama
    let calculatedVolume;
    const r = olculer.yaricap;
    const h = olculer.yukseklik;
    
    switch(sekil) {
      case 'prizma':
        calculatedVolume = olculer.uzunluk * olculer.genislik * olculer.yukseklik;
        break;
      case 'kup':
        calculatedVolume = Math.pow(olculer.kenar, 3);
        break;
      case 'kure':
        calculatedVolume = (4/3) * Math.PI * Math.pow(r, 3);
        break;
      case 'silindir':
        calculatedVolume = Math.PI * Math.pow(r, 2) * h;
        break;
      case 'koni':
        calculatedVolume = (1/3) * Math.PI * Math.pow(r, 2) * h;
        break;
      default:
        calculatedVolume = 0;
    }

    setHacim(calculatedVolume.toFixed(2));
  };

  const temizle = () => {
    setSekil('kup');
    setOlculer({});
    setHacim(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container hacim">
      <div className="baslik-bolumu">
        <h1>Geometrik Hacim Hesaplama</h1>
        <p>Şekil seçerek hacim hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div>
          <label>
            Şekil Seçin
            <select 
              name="sekil" 
              value={sekil} 
              onChange={handleChange}
              className="tarih-input"
            >
              <option value="prizma">Dikdörtgenler Prizması</option>
              <option value="kup">Küp</option>
              <option value="kure">Küre</option>
              <option value="silindir">Silindir</option>
              <option value="koni">Koni</option>
            </select>
          </label>
        </div>

        <div>
          {sekilConfig[sekil].inputs.map((input, index) => (
            <div>
              <label>
                {sekilConfig[sekil].labels[index]}
                <input
                  type="number"
                  name={input}
                  value={olculer[input] || ''}
                  onChange={handleChange}
                  min="0.1"
                  step="0.1"
                  className="tarih-input"
                  placeholder={`Ör: ${index + 1 * 5}`}
                />
              </label>
            </div>
          ))}
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {hacim && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Hacim</h2>
          <div className="hacim-sonuc">
            <span className="hacim-deger">{hacim}</span>
            <span className="hacim-birim">birim³</span>
          </div>
          <p className="uyari">
            * Sonuçlar 2 ondalık basamağa yuvarlanmıştır
          </p>
        </div>
      )}
    </div>
  );
};

export default HacimHesaplama;