import React, { useState } from 'react';
import './GelirVergisiHesaplama.css';

const VergiHesaplama = () => {
  const [gelir, setGelir] = useState('');
  const [vergi, setVergi] = useState(0);
  const [hata, setHata] = useState('');

  const vergiDilimleri = [
    { limit: 158000, oran: 0.15, oncekiToplam: 0 },
    { limit: 330000, oran: 0.20, oncekiToplam: 23700 },
    { limit: 800000, oran: 0.27, oncekiToplam: 58100 },
    { limit: 4300000, oran: 0.35, oncekiToplam: 185000 },
    { limit: Infinity, oran: 0.40, oncekiToplam: 1410000 }
  ];

  const hesaplaVergi = (tutar) => {
    let vergiMiktari = 0;
    let kalanTutar = tutar;

    for(let i = 0; i < vergiDilimleri.length; i++) {
      const dilim = vergiDilimleri[i];
      const oncekiLimit = i > 0 ? vergiDilimleri[i-1].limit : 0;
      
      if(kalanTutar > dilim.limit - oncekiLimit) {
        const dilimTutari = dilim.limit - oncekiLimit;
        vergiMiktari += dilimTutari * dilim.oran;
        kalanTutar -= dilimTutari;
      } else {
        vergiMiktari += kalanTutar * dilim.oran;
        break;
      }
      
      // Önceki dilimlerin toplam vergisini ekle
      vergiMiktari += dilim.oncekiToplam;
    }

    return vergiMiktari;
  };

  const handleHesapla = (e) => {
    e.preventDefault();
    setHata('');

    const tutar = parseFloat(gelir);
    if(!tutar || tutar < 0) {
      setHata('Lütfen geçerli bir gelir tutarı girin!');
      return;
    }

    const vergiMiktari = hesaplaVergi(tutar);
    setVergi(vergiMiktari);
  };

  const formatTL = (tutar) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(tutar);
  };

  return (
    <div className="hesaplama-container vergi">
      <div className="baslik-bolumu">
        <h1>💰 2025 Gelir Vergisi Hesaplama</h1>
        <p>Yıllık brüt gelirinizi girerek ödenecek vergiyi hesaplayın</p>
      </div>

      <form onSubmit={handleHesapla}>
        <div >
          <label>
            Yıllık Brüt Gelir (TL)
            <input
              type="number"
              value={gelir}
              onChange={(e) => setGelir(e.target.value)}
              min="0"
              step="1000"
              className="tarih-input"
              placeholder="Ör: 500000"
            />
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {vergi > 0 && (
        <div className="sonuc-paneli">
          <h2>Vergi Sonuçları</h2>
          <div className="vergi-detay">
            <div className="vergi-satir">
              <span>Brüt Gelir:</span>
              <span>{formatTL(gelir)}</span>
            </div>
            <div className="vergi-satir">
              <span>Ödenecek Vergi:</span>
              <span className="vergi-tutar">{formatTL(vergi)}</span>
            </div>
            <div className="vergi-satir">
              <span>Net Gelir:</span>
              <span>{formatTL(gelir - vergi)}</span>
            </div>
          </div>

          <div className="dilimler">
            <h3>2025 Vergi Dilimleri</h3>
            <table>
              <thead>
                <tr>
                  <th>Dilim</th>
                  <th>Vergi Oranı</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>158.000 TL'ye kadar</td>
                  <td>%15</td>
                </tr>
                <tr>
                  <td>158.001 - 330.000 TL</td>
                  <td>%20</td>
                </tr>
                <tr>
                  <td>330.001 - 800.000 TL</td>
                  <td>%27</td>
                </tr>
                <tr>
                  <td>800.001 - 4.300.000 TL</td>
                  <td>%35</td>
                </tr>
                <tr>
                  <td>4.300.000 TL üzeri</td>
                  <td>%40</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default VergiHesaplama;