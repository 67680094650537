import React, { useState } from 'react';
import './yashesaplama.css';

const YasHesaplama = () => {
  const [dogumTarihi, setDogumTarihi] = useState('');
  const [yas, setYas] = useState({ yil: 0, ay: 0, gun: 0 });
  const [hata, setHata] = useState('');

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');

    if (!dogumTarihi) {
      setHata('Lütfen doğum tarihinizi seçin!');
      return;
    }

    const dogum = new Date(dogumTarihi);
    const bugun = new Date();
    
    if (dogum > bugun) {
      setHata('Gelecekten gelen misiniz? 😊');
      return;
    }

    let yil = bugun.getFullYear() - dogum.getFullYear();
    let ay = bugun.getMonth() - dogum.getMonth();
    let gun = bugun.getDate() - dogum.getDate();

    if (gun < 0) {
      const sonAyGun = new Date(bugun.getFullYear(), bugun.getMonth(), 0).getDate();
      gun += sonAyGun;
      ay--;
    }

    if (ay < 0) {
      ay += 12;
      yil--;
    }

    setYas({ yil, ay, gun });
  };

  const temizle = () => {
    setDogumTarihi('');
    setYas({ yil: 0, ay: 0, gun: 0 });
    setHata('');
  };

  return (
    <div className="hesaplama-container yas">
      <div className="baslik-bolumu">
        <h1>🎂 Yaş Hesaplama Aracı</h1>
        <p>Doğum tarihinizi girerek tam yaşınızı öğrenin</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubu">
          <label>
            Doğum Tarihiniz
            <input
              type="date"
              value={dogumTarihi}
              onChange={(e) => setDogumTarihi(e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className="tarih-input"
            />
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {(yas.yil > 0 || yas.ay > 0 || yas.gun > 0) && (
        <div className="sonuc-paneli">
          <h2>Yaş Bilgileriniz</h2>
          <div className="yas-grid">
            <div className="yas-kutu">
              <div className="yas-deger">{yas.yil}</div>
              <div className="yas-birim">Yıl</div>
            </div>
            <div className="yas-kutu">
              <div className="yas-deger">{yas.ay}</div>
              <div className="yas-birim">Ay</div>
            </div>
            <div className="yas-kutu">
              <div className="yas-deger">{yas.gun}</div>
              <div className="yas-birim">Gün</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YasHesaplama;