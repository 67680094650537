import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import CalculationButton from './components/CalculationButton';
import KrediHesaplama from './components/KrediHesaplama';
import IhtiyacKredisiHesaplama from './components/IhtiyacKredisiHesaplama';
import KonutKredisiHesaplama from './components/KonutKredisiHesaplama';
import KrediKartiGecikmeFaizi from './components/KrediKartiGecikmeFaizi';
import TasitKredisiHesaplama from './components/TasitKredisiHesaplama';
import FaizHesaplama from './components/FaizHesaplama';
import IbanKontrol from './components/IBANKontrol';
import VadeliMevduat from './components/VadeliMevduat';
import AlesPuanHesaplama from './components/AlesPuanHesaplama';
import EhliyetPuanHesaplama from './components/EhliyetPuanHesaplama';
import LGSPuanHesaplama from './components/LGSPuanHesaplama';
import TakdirTesekkur from './components/takdirtesekkurhesaplama';
import VizeFinalHesaplama from './components/vizefinalhesaplama';
import LiseOrtalama from './components/liseortalamahesaplama';
import DogumTarihiHesaplama from './components/dogumtarihihesaplama';
import YasHesaplama from './components/yashesaplama';
import KaloriHesaplama from './components/kalorihesaplama';
import BmiHesaplama from './components/BmiHesaplama';
import IdealKiloHesaplama from './components/IdealKiloHesaplama';
import AlanHesaplama from './components/AlanHesaplama';
import CevreHesaplama from './components/CevreHesaplama';
import EbobEkokHesaplama from './components/EbobEkokHesaplama';
import FaktoriyelHesaplama from './components/FaktoriyelHesaplama';
import HacimHesaplama from './components/HacimHesaplama';
import KokluSayiHesaplama from './components/KokluSayiHesaplama';
import UsluHesaplama from './components/UsluHesaplama';
import TarihFarkiHesaplama from './components/TarihFarkiHesaplama';
import GeriSayimHesaplama from './components/GeriSayimHesaplama';
import AskerlikHesaplama from './components/AskerlikHesaplama';
import YilinKacinciGunu from './components/YilinKacinciGunu';
import GelirVergisiHesaplama from './components/GelirVergisiHesaplama';
import GumrukHesaplama from './components/GumrukVergisiHesaplama';
import KDVHesaplama from './components/KDVHesaplama';
import MesafeHesaplama from './components/MesafeHesaplama';
import TaksiHesaplama from './components/TaksiHesaplama';
import YakitHesaplama from './components/YakitHesaplama';
import KarOraniHesaplama from './components/KarOraniHesaplama';
function App() {
  // Hesaplama türlerini burada tutuyoruz
  const calculations = [
    { icon: '💰', label: 'Kredi Hesaplama', path: '/kredi-hesaplama' },
    { icon: '⚖️', label: 'Beden Kitle İndeksi', path: '/bki-hesaplama' },
    { icon: '📈', label: 'Faiz Hesaplama', path: '/faiz-hesaplama' },
    { icon: '🎂', label: 'Yaş Hesaplama', path: '/yas-hesaplama' },
    { icon: '⏳', label: 'Ne Kadar Zaman Kaldı (Canlı Geri Sayım)', path: 'ne-kadar-zaman-kaldi' },
    { icon: '💸', label: 'Kâr Oranı Hesaplama', path: '/kar-orani-hesaplama' },
    { icon: '🏋️‍♀️', label: 'İdeal Kilo Hesaplama', path: '/ideal-kilo-hesaplama' },
    { icon: '📅', label: 'İki Tarih Arası Hesaplama', path: '/iki-tarih-arasi-hesaplama' },
    { icon: '⛽', label: 'Yakıt Tüketimi Hesaplama', path: '/yakit-tuketimi-hesaplama' },
    { icon: '💰', label: 'Vadeli Mevduat Faizi Hesaplama', path: 'vadeli-mevduat-faizi-hesaplama' },
    { icon: '🎖️', label: 'Şafak Hesaplama', path: 'safak-hesaplama' },
    { icon: '📏', label: 'Mesafe Hesaplama', path: 'mesafe-hesaplama' },
    { icon: '📅', label: 'İki Tarih Arası Hesaplama', path: 'iki-tarih-arasi-hesaplama' },
    { icon: '🍽️', label: 'Günlük Kalori İhtiyacı Hesaplama', path: 'gunluk-kalori-hesaplama' },
    { icon: '🎓', label: 'Lise Ortalama Hesaplama', path: 'lise-ortalama-hesaplama' },
    { icon: '💰', label: 'KDV Hesaplama', path: 'kdv-hesaplama' },


  ];

  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Ana Sayfa */}
        <Route
          path="/"
          element={
            <div style={styles.container}>
              <h1 style={styles.heading}>Popüler Hesaplamalar</h1>
              <div style={styles.grid}>
                {calculations.map((calc, index) => (
                  <CalculationButton
                    key={index}
                    icon={calc.icon}
                    label={calc.label}
                    path={calc.path}
                  />
                ))}
              </div>
            </div>
          }
        />

        {/* Kredi Hesaplama Sayfası */}
        <Route path="/kredi-hesaplama" element={<KrediHesaplama />} />
        <Route path="/kredi-ihtiyac" element={<IhtiyacKredisiHesaplama />} />
        <Route path="/kredi-tasit" element={<TasitKredisiHesaplama />} />
        <Route path="/kredi-konut" element={<KonutKredisiHesaplama />} />
        <Route path="/kredi-karti" element={<KrediKartiGecikmeFaizi />} />
        <Route path="/faiz-hesaplama" element={<FaizHesaplama />} />
        <Route path="/iban-dogrulama" element={<IbanKontrol />} />
        <Route path="/vadeli-mevduat-faizi-hesaplama" element={<VadeliMevduat />} />
        <Route path="/ales-puan-hesaplama" element={<AlesPuanHesaplama />} />
        <Route path="/ehliyet-puan-hesaplama" element={<EhliyetPuanHesaplama />} />
        <Route path="/lgs-puan-hesaplama" element={<LGSPuanHesaplama />} />
        <Route path="/takdir-tesekkur-hesaplama" element={<TakdirTesekkur />} />
        <Route path="/vize-final-hesaplama" element={<VizeFinalHesaplama />} />
        <Route path="/lise-ortalama-hesaplama" element={<LiseOrtalama />} />
        <Route path="/dogum-tarihi-hesaplama" element={<DogumTarihiHesaplama />} />
        <Route path="/yas-hesaplama" element={<YasHesaplama />} />
        <Route path="/gunluk-kalori-hesaplama" element={<KaloriHesaplama />} />
        <Route path="/bki-hesaplama" element={<BmiHesaplama />} />
        <Route path="/ideal-kilo-hesaplama" element={<IdealKiloHesaplama />} />
        <Route path="/alan-hesaplama" element={<AlanHesaplama />} />
        <Route path="/cevre-hesaplama" element={<CevreHesaplama/>} />
        <Route path="/ebob-ekok-hesaplama" element={<EbobEkokHesaplama/>} />
        <Route path="/faktoriyel-hesaplama" element={<FaktoriyelHesaplama/>} />
        <Route path="/hacim-hesaplama" element={<HacimHesaplama/>} />
        <Route path="/koklu-sayi-hesaplama" element={<KokluSayiHesaplama/>} />
        <Route path="/uslu-sayi-hesaplama" element={<UsluHesaplama/>} />
        <Route path="/iki-tarih-arasi-hesaplama" element={<TarihFarkiHesaplama/>} />
        <Route path="/ne-kadar-zaman-kaldi" element={<GeriSayimHesaplama/>} />
        <Route path="/safak-hesaplama" element={<AskerlikHesaplama/>} />
        <Route path="/yilin-kacinci-gunu" element={<YilinKacinciGunu/>} />
        <Route path="/gelir-vergisi-hesaplama" element={<GelirVergisiHesaplama/>} />
        <Route path="/gumruk-vergisi-hesaplama" element={<GumrukHesaplama/>} />
        <Route path="/kdv-hesaplama" element={<KDVHesaplama/>} />
        <Route path="/mesafe-hesaplama" element={<MesafeHesaplama/>} />
        <Route path="/taksi-ucreti-hesaplama" element={<TaksiHesaplama/>} />
        <Route path="/yakit-tuketimi-hesaplama" element={<YakitHesaplama/>} />
        <Route path="/kar-orani-hesaplama" element={<KarOraniHesaplama/>} />










        {/* Diğer sayfalar için örnek */}
        {/* <Route path="/bki" element={<BkiHesaplama />} /> */}
      </Routes>
    </Router>
  );
}

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
  },
  heading: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '38px',
    fontWeight: '700',
    color: '#333',
    textAlign: 'center',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
    gap: '20px',
    marginTop: '20px',
  },
};

export default App;
