import React, { useState } from 'react';
import './kalorihesaplama.css';

const KaloriHesaplama = () => {
  const [inputs, setInputs] = useState({
    gender: 'male',
    age: '',
    height: '',
    weight: '',
    activity: '1.2'
  });
  const [calories, setCalories] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    // Validasyon
    if (!inputs.age || !inputs.height || !inputs.weight) {
      setHata('Lütfen tüm alanları doldurun!');
      return;
    }
    
    const age = parseInt(inputs.age);
    const height = parseInt(inputs.height);
    const weight = parseInt(inputs.weight);
    
    if (age < 15 || age > 120) {
      setHata('Yaş 15-120 arasında olmalı!');
      return;
    }

    // BMR Hesaplama
    let bmr;
    if(inputs.gender === 'male') {
      bmr = (10 * weight) + (6.25 * height) - (5 * age) + 5;
    } else {
      bmr = (10 * weight) + (6.25 * height) - (5 * age) - 161;
    }
    
    const total = Math.round(bmr * parseFloat(inputs.activity));
    setCalories(total);
  };

  const temizle = () => {
    setInputs({
      gender: 'male',
      age: '',
      height: '',
      weight: '',
      activity: '1.2'
    });
    setCalories(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container kalori">
      <div className="baslik-bolumu">
        <h1>⚖️ Günlük Kalori İhtiyacı</h1>
        <p>Vücut parametrelerinizi girerek günlük kalori ihtiyacınızı hesaplayın</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grubu">
          <label>
            Cinsiyet
            <select
              name="gender"
              value={inputs.gender}
              onChange={handleChange}
              className="tarih-input"
            >
              <option value="male">Erkek</option>
              <option value="female">Kadın</option>
            </select>
          </label>
        </div>

        <div className="input-grid">
          <div className="input-grubu">
            <label>
              Yaş
              <input
                type="number"
                name="age"
                value={inputs.age}
                onChange={handleChange}
                min="15"
                max="120"
                className="tarih-input"
                placeholder="Ör: 30"
              />
            </label>
          </div>

          <div className="input-grubu">
            <label>
              Boy (cm)
              <input
                type="number"
                name="height"
                value={inputs.height}
                onChange={handleChange}
                min="100"
                max="250"
                className="tarih-input"
                placeholder="Ör: 175"
              />
            </label>
          </div>

          <div className="input-grubu">
            <label>
              Kilo (kg)
              <input
                type="number"
                name="weight"
                value={inputs.weight}
                onChange={handleChange}
                min="30"
                max="300"
                className="tarih-input"
                placeholder="Ör: 75"
              />
            </label>
          </div>
        </div>

        <div className="input-grubu">
          <label>
            Aktivite Seviyesi
            <select
              name="activity"
              value={inputs.activity}
              onChange={handleChange}
              className="tarih-input"
            >
              <option value="1.2">Hareketsiz (Az veya hiç egzersiz)</option>
              <option value="1.375">Hafif Aktif (Haftada 1-3 gün)</option>
              <option value="1.55">Orta Aktif (Haftada 3-5 gün)</option>
              <option value="1.725">Çok Aktif (Haftada 6-7 gün)</option>
              <option value="1.9">Ekstra Aktif (Ağır egzersiz ve fiziksel iş)</option>
            </select>
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {calories && (
        <div className="sonuc-paneli">
          <h2>Günlük Kalori İhtiyacınız</h2>
          <div className="kalori-sonuc">
            <span className="kalori-deger">{calories}</span>
            <span className="kalori-birim">kcal/gün</span>
          </div>
          <p className="uyari">
            * Bu değer tahmini olup kişisel metabolizma ve diğer faktörlere göre değişebilir
          </p>
        </div>
      )}
    </div>
  );
};

export default KaloriHesaplama;