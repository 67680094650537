import React, { useState } from 'react';
import './AskerlikHesaplama.css';

const AskerlikHesaplama = () => {
  const [form, setForm] = useState({
    serviceType: 'bedelli',
    startDate: '',
    earlyDischarge: 0,
    penaltyDays: 0
  });
  const [remainingDays, setRemainingDays] = useState(null);
  const [hata, setHata] = useState('');

  const serviceDurations = {
    bedelli: 1,    // 1 Ay = 30 gün
    kisa: 6,       // 6 Ay = 180 gün
    uzun: 12       // 12 Ay = 360 gün
  };

  const calculateDays = () => {
    const startDate = new Date(form.startDate);
    if(isNaN(startDate)) {
      setHata('Geçersiz başlangıç tarihi!');
      return null;
    }

    const baseEndDate = new Date(startDate);
    baseEndDate.setMonth(startDate.getMonth() + serviceDurations[form.serviceType]);
    
    // Yeni Hesaplama Mantığı
    const adjustment = form.earlyDischarge - form.penaltyDays;
    
    const finalEndDate = new Date(baseEndDate);
    finalEndDate.setDate(baseEndDate.getDate() - adjustment);
    
    const today = new Date();
    const diffTime = finalEndDate - today;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHata('');
    
    if(!form.startDate) {
      setHata('Lütfen başlangıç tarihini seçin!');
      return;
    }

    const days = calculateDays();
    if(days < 0) {
      setHata('Askerlik süreniz zaten tamamlandı! 🎉');
      setRemainingDays(0);
    } else {
      setRemainingDays(days);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const temizle = () => {
    setForm({
      serviceType: 'bedelli',
      startDate: '',
      earlyDischarge: 0,
      penaltyDays: 0
    });
    setRemainingDays(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container askerlik">
      <div className="baslik-bolumu">
        <h1>🎖️ Askerlik Şafak Hesaplama</h1>
        <p>Askerlik bitiş tarihini ve kalan günleri öğrenin</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <div>
            <label>
              Askerlik Türü
              <select
                name="serviceType"
                value={form.serviceType}
                onChange={handleChange}
                className="tarih-input"
              >
                <option value="bedelli">Bedelli (1 Ay)</option>
                <option value="kisa">Kısa Dönem (6 Ay)</option>
                <option value="uzun">Uzun Dönem (12 Ay)</option>
              </select>
            </label>
          </div>

          <div>
            <label>
              Başlangıç Tarihi
              <input
                type="date"
                name="startDate"
                value={form.startDate}
                onChange={handleChange}
                className="tarih-input"
                required
              />
            </label>
          </div>
        </div>

        <div>
          <div >
            <label>
              Erken Terhis (0-30 gün)
              <input
                type="number"
                name="earlyDischarge"
                value={form.earlyDischarge}
                onChange={handleChange}
                min="0"
                max="30"
                className="tarih-input"
              />
            </label>
          </div>

          <div>
            <label>
              Ceza Günü (0-90 gün)
              <input
                type="number"
                name="penaltyDays"
                value={form.penaltyDays}
                onChange={handleChange}
                min="0"
                max="90"
                className="tarih-input"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {remainingDays !== null && (
        <div className="sonuc-paneli">
          <h2>Kalan Askerlik Süresi</h2>
          <div className="safak-sonuc">
            <span className="gun">{remainingDays}</span>
            <span className="birim">gün</span>
          </div>
          <p className="uyari">
            * Hesaplamalar teorik olup resmi süreçleri yansıtmayabilir
          </p>
        </div>
      )}
    </div>
  );
};

export default AskerlikHesaplama;