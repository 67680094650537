import React from 'react';
import { Link } from 'react-router-dom';
import './CalculationButton.css'; // CSS dosyasını ekledik!

const CalculationButton = ({ icon, label, path }) => {
  return (
    <Link to={path} className="link">
      <div className="button">
        <span className="icon">{icon}</span>
        <span className="label">{label}</span>
      </div>
    </Link>
  );
};

export default CalculationButton;
