import React, { useState } from 'react';
import './KokluSayiHesaplama.css';

const KokluSayiHesaplama = () => {
  const [inputs, setInputs] = useState({ sayi: '', derece: '' });
  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setSonuc(null);

    const sayi = parseFloat(inputs.sayi);
    const derece = parseFloat(inputs.derece);

    // Validasyonlar
    if (!inputs.sayi || !inputs.derece) {
      setHata('Lütfen tüm alanları doldurun!');
      return;
    }

    if (derece === 0) {
      setHata('Derece 0 olamaz!');
      return;
    }

    if (derece % 2 === 0 && sayi < 0) {
      setHata('Çift dereceli kök için sayı pozitif olmalı!');
      return;
    }

    // Hesaplama
    try {
      const result = Math.pow(Math.abs(sayi), 1/derece) * (sayi < 0 && derece % 2 !== 0 ? -1 : 1);
      setSonuc(result);
    } catch {
      setHata('Geçersiz hesaplama!');
    }
  };

  const temizle = () => {
    setInputs({ sayi: '', derece: '' });
    setSonuc(null);
    setHata('');
  };

  return (
    <div className="hesaplama-container koklu">
      <div className="baslik-bolumu">
        <h1>√ Köklü Sayı Hesaplama</h1>
        <p>Sayının istenen dereceden kökünü bulun</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              Sayı
              <input
                type="number"
                name="sayi"
                value={inputs.sayi}
                onChange={handleChange}
                step="any"
                className="tarih-input"
                placeholder="Ör: 8"
              />
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              Kök Derecesi
              <input
                type="number"
                name="derece"
                value={inputs.derece}
                onChange={handleChange}
                min="1"
                className="tarih-input"
                placeholder="Ör: 3"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc !== null && (
        <div className="sonuc-paneli">
          <h2>Hesaplanan Kök Değeri</h2>
          <div className="kok-sonuc">
            <sup>{inputs.derece}</sup>√{inputs.sayi} = 
            <span className="sonuc-deger">
              {parseFloat(sonuc.toFixed(4))}
            </span>
          </div>
          <p className="uyari">
            * Sonuçlar 4 ondalık basamağa yuvarlanmıştır
          </p>
        </div>
      )}
    </div>
  );
};

export default KokluSayiHesaplama;