import React, { useState } from 'react';
import './IBANKontrol.css';
import { formatNumber } from './utils/format';


const IbanKontrol = () => {
  const [iban, setIban] = useState('');
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState('');

  // IBAN doğrulama fonksiyonu
  const validateIban = (iban) => {
    // IBAN'ı büyük harfe çevirin ve boşlukları kaldırın
    iban = iban.toUpperCase().replace(/\s+/g, '');

    // IBAN uzunluğunu kontrol et
    if (iban.length < 15 || iban.length > 34) {
      return false;
    }

    // IBAN'ı ülke kodu + kontrol rakamları + hesap numarası olarak ayırın
    let countryCode = iban.slice(0, 2);
    let checkDigits = iban.slice(2, 4);
    let accountNumber = iban.slice(4);

    // Harfleri sayıya dönüştür
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const alphabetMap = {};
    for (let i = 0; i < alphabet.length; i++) {
      alphabetMap[alphabet[i]] = i + 10; // A=10, B=11, ..., Z=35
    }

    // IBAN'ı sayı formatına dönüştür
    let ibanNumeric = accountNumber + countryCode + checkDigits;

    // Harfleri sayı karşılıklarına dönüştürün
    ibanNumeric = ibanNumeric.split('').map(char => {
      if (alphabetMap[char]) {
        return alphabetMap[char];
      }
      return char;
    }).join('');

    // Mod 97 hesaplaması
    let remainder = ibanNumeric;
    while (remainder.length > 2) {
      let chunk = remainder.slice(0, 9); // 9 haneli bir sayı al
      remainder = (parseInt(chunk) % 97) + remainder.slice(chunk.length);
    }

    return parseInt(remainder) % 97 === 1;
  };

  const handleCheck = () => {
    setError('');
    setIsValid(null);

    // IBAN'ı doğrulama
    if (iban) {
      const valid = validateIban(iban);
      setIsValid(valid);
    } else {
      setError('Lütfen geçerli bir IBAN numarası giriniz.');
    }
  };

  const handleClear = () => {
    setIban('');
    setIsValid(null);
    setError('');
  };

  return (
    <div className="iban-container">
      <h1 className="iban-title">IBAN Doğrulama</h1>
      <p style={{ textAlign: 'center', marginBottom: '30px',fontSize: '18px', fontFamily:'Poppins' }}>
      Herhangi bir IBAN Kodunun Doğruluğunu Kontrol Eder. 
            Örnek: TR76 0009 9012 3456 7800 1000 01
      </p>
      <form className="iban-form">
        <label>
          * IBAN Numarası:
          <input
            type="text"
            value={iban}
            onChange={(e) => setIban(e.target.value)}
            placeholder="IBAN numarasını girin"
          />
        </label>
        {error && <p className="error-message">{error}</p>}
        <div className="button-group">
          <button type="button" className="check-button" onClick={handleCheck}>
            Doğrula
          </button>
          <button type="button" className="clear-button" onClick={handleClear}>
            Temizle
          </button>
        </div>
      </form>

      {isValid !== null && (
        <div className={`result-card ${isValid ? 'valid' : 'invalid'}`}>
          <h2>{isValid ? 'Geçerli IBAN' : 'Geçersiz IBAN'}</h2>
          <p>{isValid ? 'IBAN numaranız geçerli bir formatta.' : 'IBAN numaranız geçerli değil.'}</p>
        </div>
      )}
    </div>
  );
};

export default IbanKontrol;
