import React, { useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts'; // Grafik için
import './TasitKredisiHesaplama.css';
import { formatNumber } from './utils/format';


const TasitKredisiHesaplama = () => {
  const [tutar, setTutar] = useState('');
  const [vade, setVade] = useState('');
  const [faiz, setFaiz] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [error, setError] = useState('');

  const handleHesapla = (e) => {
    e.preventDefault();
    setError(''); // Önceki hatayı temizle

    const t = parseFloat(tutar);
    const v = parseInt(vade);
    const f = parseFloat(faiz) / 100;

    if (!t || !v || !f || t <= 0 || v <= 0 || f <= 0) {
      setError('Lütfen tüm alanları geçerli değerlerle doldurun!');
      return;
    }

    const aylikFaiz = f / 12;
    const taksit = (t * aylikFaiz) / (1 - Math.pow(1 + aylikFaiz, -v));
    const toplamOdeme = taksit * v;
    const toplamFaiz = toplamOdeme - t;

    setSonuc({
      aylikTaksit: taksit.toFixed(2),
      toplamOdeme: toplamOdeme.toFixed(2),
      toplamFaiz: toplamFaiz.toFixed(2)
    });
  };

  const handleTemizle = () => {
    setTutar('');
    setVade('');
    setFaiz('');
    setSonuc(null);
    setError('');
  };
  

  const COLORS = ['#0088FE', '#FF8042']; // Grafik renkleri
  const data = sonuc
    ? [
        { name: 'Anapara', value: parseFloat(tutar) },
        { name: 'Faiz', value: parseFloat(sonuc.toplamFaiz) }
      ]
    : [];

  return (
    <div className="tasit-container">
      <h1 className="tasit-title">Taşıt Kredisi Hesaplama</h1>
      <p style={{ textAlign: 'center', marginBottom: '30px',fontSize: '18px', fontFamily:'Poppins' }}>
      Taşıt krediniz için aylık taksit ve toplam ödeme tutarlarını kolayca hesaplayın.

      </p>
      <form className="tasit-form" onSubmit={handleHesapla}>
        <label>
          * Kredi Tutarı:
          <input type="number" value={tutar} onChange={(e) => setTutar(e.target.value)} placeholder="Örn: 100000" />
        </label>
        <label>
          * Vade (Ay):
          <input type="number" value={vade} onChange={(e) => setVade(e.target.value)} placeholder="Örn: 12" />
        </label>
        <label>
          * Faiz Oranı (%):
          <input type="number" step="0.01" value={faiz} onChange={(e) => setFaiz(e.target.value)} placeholder="Örn: 1.79" />
        </label>
        {error && <p className="error-message">{error}</p>}
        <div className="button-group">
          <button type="submit" className="hesapla-button">Hesapla</button>
          <button type="button" className="temizle-button" onClick={handleTemizle}>Temizle</button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-card">
          <h2>Sonuçlar</h2>
          <p><strong>Aylık Taksit Tutarı:</strong> {formatNumber(sonuc.aylikTaksit)} TL</p>
          <p><strong>Toplam Ödenecek Tutar:</strong> {formatNumber(sonuc.toplamOdeme)} TL</p>
          <p><strong>Toplam Faiz:</strong> {formatNumber(sonuc.toplamFaiz)} TL</p>

          <div className="chart-container">
            <PieChart width={400} height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
                label={({ name, percent }) => ` ${(percent * 100).toFixed(1)}%`} // Yüzdelik oran
              >
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${formatNumber(value)} TL`} />
 {/* Hoverda TL cinsi */}
            </PieChart>
          </div>
        </div>
      )}
    </div>
  );
};

export default TasitKredisiHesaplama;
