import React, { useState } from 'react';
import './GumrukVergisiHesaplama.css';

const GumrukHesaplama = () => {
  const [urunDegeri, setUrunDegeri] = useState('');
  const [otvVarMi, setOtvVarMi] = useState(false);
  const [toplamVergi, setToplamVergi] = useState(0);
  const [toplamMaliyet, setToplamMaliyet] = useState(0);
  const [hata, setHata] = useState('');

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    if(!urunDegeri || urunDegeri <= 0) {
      setHata('Lütfen geçerli bir ürün değeri girin!');
      return;
    }

    const deger = parseFloat(urunDegeri);
    const gumrukVergisi = deger * 0.60;
    let otv = 0;

    if(otvVarMi) {
      otv = (deger + gumrukVergisi) * 0.20;
    }

    const toplam = gumrukVergisi + otv;
    setToplamVergi(toplam);
    setToplamMaliyet(deger + toplam);
  };

  const formatTL = (tutar) => {
    return new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(tutar);
  };

  const temizle = () => {
    setUrunDegeri('');
    setOtvVarMi(false);
    setToplamVergi(0);
    setToplamMaliyet(0);
    setHata('');
  };

  return (
    <div className="hesaplama-container gumruk">
      <div className="baslik-bolumu">
        <h1>🌍 Gümrük Vergisi Hesaplama</h1>
        <p>Ürün değerini girerek toplam maliyeti öğrenin</p>
      </div>

      <form onSubmit={hesapla}>
        <div>
          <label>
            Ürün Değeri (TL)
            <input
              type="number"
              value={urunDegeri}
              onChange={(e) => setUrunDegeri(e.target.value)}
              min="0"
              step="100"
              className="tarih-input"
              placeholder="Ör: 5000"
            />
          </label>
        </div>

        <div >
          <label>
            <input
              type="checkbox"
              checked={otvVarMi}
              onChange={(e) => setOtvVarMi(e.target.checked)}
            />
            ÖTV Uygulansın (%20)
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {toplamVergi > 0 && (
        <div className="sonuc-paneli">
          <h2>Hesaplama Sonuçları</h2>
          <div className="vergi-detaylari">
            <div className="vergi-satir">
              <span>Ürün Değeri:</span>
              <span>{formatTL(urunDegeri)}</span>
            </div>
            <div className="vergi-satir">
              <span>Gümrük Vergisi (%60):</span>
              <span>{formatTL(urunDegeri * 0.60)}</span>
            </div>
            {otvVarMi && (
              <div className="vergi-satir">
                <span>ÖTV (%20):</span>
                <span>{formatTL(parseFloat(urunDegeri) * 1.60 * 0.20)}</span>
              </div>
            )}
            <div className="vergi-satir toplam">
              <span>Toplam Vergi:</span>
              <span className="kirmizi">{formatTL(toplamVergi)}</span>
            </div>
            <div className="vergi-satir">
              <span>Ödenecek Toplam Tutar:</span>
              <span className="yesil">{formatTL(toplamMaliyet)}</span>
            </div>
          </div>

          <div className="vergi-tablosu">
            <table>
              <thead>
                <tr>
                  <th>Vergi Türü</th>
                  <th>Oran</th>
                  <th>Hesaplama Formülü</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Gümrük Vergisi</td>
                  <td>%60</td>
                  <td>Ürün Değeri × 0.60</td>
                </tr>
                <tr>
                  <td>Özel Tüketim Vergisi</td>
                  <td>%20</td>
                  <td>(Ürün Değeri + Gümrük Vergisi) × 0.20</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default GumrukHesaplama;