import React, { useState, useEffect } from 'react';
import './GeriSayimHesaplama.css';

const GeriSayimHesaplama = () => {
  const [hedefTarih, setHedefTarih] = useState('');
  const [kalanZaman, setKalanZaman] = useState({});
  const [hata, setHata] = useState('');
  const [aktif, setAktif] = useState(false);

  const zamanHesapla = (hedef) => {
    const simdi = new Date();
    const fark = hedef - simdi;

    if(fark < 0) {
      setAktif(false);
      return { bitis: true };
    }

    const saniye = Math.floor(fark / 1000);
    const dakika = Math.floor(saniye / 60);
    const saat = Math.floor(dakika / 60);
    const gun = Math.floor(saat / 24);

    return {
      gun: gun,
      saat: saat % 24,
      dakika: dakika % 60,
      saniye: saniye % 60,
      bitis: false
    };
  };

  const tarihFarkiHesapla = (baslangic, hedef) => {
    let yilFark = hedef.getFullYear() - baslangic.getFullYear();
    let ayFark = hedef.getMonth() - baslangic.getMonth();
    let gunFark = hedef.getDate() - baslangic.getDate();

    if (gunFark < 0) {
      const sonAyGun = new Date(hedef.getFullYear(), hedef.getMonth(), 0).getDate();
      gunFark += sonAyGun;
      ayFark--;
    }

    if (ayFark < 0) {
      ayFark += 12;
      yilFark--;
    }

    return { yil: yilFark, ay: ayFark, gun: gunFark };
  };

  useEffect(() => {
    let interval;
    if(aktif && hedefTarih) {
      interval = setInterval(() => {
        const hedef = new Date(hedefTarih);
        const detayliFark = tarihFarkiHesapla(new Date(), hedef);
        const anlikFark = zamanHesapla(hedef);
        
        if(anlikFark.bitis) {
          setKalanZaman({ ...detayliFark, bitis: true });
          setAktif(false);
        } else {
          setKalanZaman({ ...detayliFark, ...anlikFark });
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [aktif, hedefTarih]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setHata('');
    
    if(!hedefTarih) {
      setHata('Lütfen bir tarih seçin!');
      return;
    }

    const secilenTarih = new Date(hedefTarih);
    if(secilenTarih < new Date()) {
      setHata('Geçmiş tarih seçemezsiniz!');
      return;
    }

    setAktif(true);
  };

  const temizle = () => {
    setHedefTarih('');
    setKalanZaman({});
    setAktif(false);
    setHata('');
  };

  return (
    <div className="hesaplama-container geri-sayim">
      <div className="baslik-bolumu">
        <h1>⏳ Kalan Zaman Hesaplama</h1>
        <p>Hedef tarihe kadar kalan süreyi görün</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="input-grubuu">
          <label>
            Hedef Tarih
            <input
              type="datetime-local"
              value={hedefTarih}
              onChange={(e) => setHedefTarih(e.target.value)}
              min={new Date().toISOString().slice(0, 16)}
              className="tarih-input"
            />
          </label>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            {aktif ? 'Güncelleniyor...' : 'Başlat'}
          </button>
        </div>
      </form>

      {(Object.keys(kalanZaman).length > 0 && !kalanZaman.bitis) && (
        <div className="sonuc-paneli">
          <h2>Kalan Zaman</h2>
          <div className="zaman-grid-detay">
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.yil}</div>
              <div className="zaman-birim">Yıl</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.ay}</div>
              <div className="zaman-birim">Ay</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.gun}</div>
              <div className="zaman-birim">Gün</div>
            </div>
          </div>
          <div className="zaman-grid-anlik">
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.saat}</div>
              <div className="zaman-birim">Saat</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.dakika}</div>
              <div className="zaman-birim">Dakika</div>
            </div>
            <div className="zaman-kutu">
              <div className="zaman-deger">{kalanZaman.saniye}</div>
              <div className="zaman-birim">Saniye</div>
            </div>
          </div>
        </div>
      )}

      {kalanZaman.bitis && (
        <div className="sonuc-paneli bitis-mesaji">
          🎉 Hedef tarihe ulaşıldı!
        </div>
      )}
    </div>
  );
};

export default GeriSayimHesaplama;