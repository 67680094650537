import React, { useState } from 'react';
import './BmiHesaplama.css';

const BmiHesaplama = () => {
  const [inputs, setInputs] = useState({
    height: '',
    weight: ''
  });
  const [bmi, setBmi] = useState(null);
  const [hata, setHata] = useState('');
  const [kategori, setKategori] = useState('');

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    });
  };

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    setBmi(null);
    setKategori('');

    if (!inputs.height || !inputs.weight) {
      setHata('Lütfen boy ve kilonuzu girin!');
      return;
    }

    const height = parseFloat(inputs.height);
    const weight = parseFloat(inputs.weight);

    if (height <= 0 || weight <= 0) {
      setHata('Geçerli değerler girin!');
      return;
    }

    const heightInMeter = height / 100;
    const calculatedBmi = weight / (heightInMeter * heightInMeter);
    const roundedBmi = Math.round(calculatedBmi * 10) / 10;

    setBmi(roundedBmi);
    determineCategory(roundedBmi);
  };

  const determineCategory = (value) => {
    if (value < 18.5) {
      setKategori('Zayıf');
    } else if (value < 24.9) {
      setKategori('Normal');
    } else if (value < 29.9) {
      setKategori('Fazla Kilolu');
    } else {
      setKategori('Obez');
    }
  };

  const temizle = () => {
    setInputs({
      height: '',
      weight: ''
    });
    setBmi(null);
    setHata('');
    setKategori('');
  };

  const getCategoryColor = () => {
    switch(kategori) {
      case 'Zayıf': return '#2196F3';
      case 'Normal': return '#4CAF50';
      case 'Fazla Kilolu': return '#FF9800';
      case 'Obez': return '#F44336';
      default: return '#718096';
    }
  };

  return (
    <div className="hesaplama-container bmi">
      <div className="baslik-bolumu">
        <h1>Beden Kitle İndeksi Hesaplama</h1>
        <p>Boy ve kilonuzu girerek BMI değerinizi öğrenin</p>
      </div>

      <form onSubmit={hesapla}>
        <div className="input-gridd">
          <div className="input-grubuu">
            <label>
              Boy (cm)
              <input
                type="number"
                name="height"
                value={inputs.height}
                onChange={handleChange}
                min="50"
                max="250"
                className="tarih-input"
                placeholder="Ör: 175"
              />
            </label>
          </div>

          <div className="input-grubuu">
            <label>
              Kilo (kg)
              <input
                type="number"
                name="weight"
                value={inputs.weight}
                onChange={handleChange}
                min="30"
                max="300"
                className="tarih-input"
                placeholder="Ör: 75"
              />
            </label>
          </div>
        </div>

        {hata && <div className="hata-mesaji">⚠️ {hata}</div>}

        <div className="aksiyon-bolum">
          <button type="button" className="temizle-btn" onClick={temizle}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {bmi && (
        <div className="sonuc-paneli">
          <h2>BMI Sonucunuz</h2>
          <div className="bmi-sonuc">
            <div className="bmi-deger" style={{ color: getCategoryColor() }}>
              {bmi}
            </div>
            <div className="bmi-kategori" style={{ backgroundColor: getCategoryColor() }}>
              {kategori}
            </div>
          </div>
          <div className="bmi-tablosu">
            <div className="bmi-aralik">
              <span>18.5 altı</span>
              <span>Zayıf</span>
              <div className="renk-kutu" style={{ backgroundColor: '#2196F3' }}></div>
            </div>
            <div className="bmi-aralik">
              <span>18.5 - 24.9</span>
              <span>Normal</span>
              <div className="renk-kutu" style={{ backgroundColor: '#4CAF50' }}></div>
            </div>
            <div className="bmi-aralik">
              <span>25 - 29.9</span>
              <span>Fazla Kilolu</span>
              <div className="renk-kutu" style={{ backgroundColor: '#FF9800' }}></div>
            </div>
            <div className="bmi-aralik">
              <span>30 ve üzeri</span>
              <span>Obez</span>
              <div className="renk-kutu" style={{ backgroundColor: '#F44336' }}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BmiHesaplama;