import React, { useState } from 'react';
import './YakitHesaplama.css';

const YakitHesaplama = () => {
  const [mod, setMod] = useState(1);
  const [veri, setVeri] = useState({
    odeme: '',
    mesafe: '',
    tuketim: '',
    yakitFiyati: 35 // TL/Litre varsayılan
  });
  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const modlar = [
    "Ödenen Tutar + Mesafe → Tüketim Hesapla",
    "Ödenen Tutar + Tüketim → Mesafe Hesapla",
    "Mesafe + Tüketim → Ödenecek Tutar Hesapla"
  ];

  const hesapla = (e) => {
    e.preventDefault();
    setHata('');
    
    const { odeme, mesafe, tuketim, yakitFiyati } = veri;
    const yakitFiyat = parseFloat(yakitFiyati);
    
    try {
      let calculation;
      switch(mod) {
        case 1:
          calculation = ((parseFloat(odeme) / yakitFiyat) / parseFloat(mesafe)) * 100;
          setSonuc(`${calculation.toFixed(2)} L/100km`);
          break;
        case 2:
          calculation = (parseFloat(odeme) / yakitFiyat) / (parseFloat(tuketim) / 100);
          setSonuc(`${calculation.toFixed(2)} km`);
          break;
        case 3:
          calculation = (parseFloat(mesafe) * (parseFloat(tuketim)/100)) * yakitFiyat;
          setSonuc(`${calculation.toFixed(2)} TL`);
          break;
      }
    } catch {
      setHata('Geçersiz değerler! Lütfen tüm alanları kontrol edin');
    }
  };

  return (
    <div className="yakit-container">
      <div className="baslik">
        <h1>⛽ Yakıt Tüketim Hesaplayıcı</h1>
        <p>3 farklı modla yakıt hesaplamaları yapın</p>
      </div>

      <div className="mod-secimi">
        {modlar.map((m, index) => (
          <button
            key={index}
            className={mod === index+1 ? 'aktif' : ''}
            onClick={() => {
              setMod(index+1);
              setSonuc(null);
            }}
          >
            {m}
          </button>
        ))}
      </div>

      <form onSubmit={hesapla}>
        <div className="input-grid">
          {mod === 1 && (
            <>
              <div className="input-grubu">
                <label>Ödenen Tutar (TL)</label>
                <input
                  type="number"
                  value={veri.odeme}
                  onChange={(e) => setVeri({...veri, odeme: e.target.value})}
                  min="1"
                  required
                />
              </div>
              <div className="input-grubu">
                <label>Gidilen Mesafe (km)</label>
                <input
                  type="number"
                  value={veri.mesafe}
                  onChange={(e) => setVeri({...veri, mesafe: e.target.value})}
                  min="1"
                  required
                />
              </div>
            </>
          )}

          {mod === 2 && (
            <>
              <div className="input-grubu">
                <label>Ödenen Tutar (TL)</label>
                <input
                  type="number"
                  value={veri.odeme}
                  onChange={(e) => setVeri({...veri, odeme: e.target.value})}
                  min="1"
                  required
                />
              </div>
              <div className="input-grubu">
                <label>Ortalama Tüketim (100 km)</label>
                <input
                  type="number"
                  value={veri.tuketim}
                  onChange={(e) => setVeri({...veri, tuketim: e.target.value})}
                  min="1"
                  step="0.1"
                  required
                />
              </div>
            </>
          )}

          {mod === 3 && (
            <>
              <div className="input-grubu">
                <label>Planlanan Mesafe (km)</label>
                <input
                  type="number"
                  value={veri.mesafe}
                  onChange={(e) => setVeri({...veri, mesafe: e.target.value})}
                  min="1"
                  required
                />
              </div>
              <div className="input-grubu">
                <label>Ortalama Tüketim (100 km)</label>
                <input
                  type="number"
                  value={veri.tuketim}
                  onChange={(e) => setVeri({...veri, tuketim: e.target.value})}
                  min="1"
                  step="0.1"
                  required
                />
              </div>
            </>
          )}

          <div className="input-grubu">
            <label>Yakıt Fiyatı (TL/L)</label>
            <input
              type="number"
              value={veri.yakitFiyati}
              onChange={(e) => setVeri({...veri, yakitFiyati: e.target.value})}
              min="1"
              step="0.1"
              required
            />
          </div>
        </div>

        {hata && <div className="hata">{hata}</div>}

        <button type="submit" className="hesapla-btn">
          Hesapla
        </button>
      </form>

      {sonuc && (
        <div className="sonuc">
          <h2>Sonuç:</h2>
          <div className="sonuc-deger">
            {mod === 1 && <span>{sonuc}</span>}
            {mod === 2 && <span>{sonuc}</span>}
            {mod === 3 && <span>{sonuc}</span>}
          </div>
        </div>
      )}

      <div className="bilgilendirme">
        <p>📌 Örnek Değerler:</p>
        <ul>
          <li>Benzin: ~35 TL/L</li>
          <li>Dizel: ~32 TL/L</li>
          <li>Ortalama Tüketim: 5-8 L/100km</li>
        </ul>
      </div>
    </div>
  );
};

export default YakitHesaplama;