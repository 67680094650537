import React, { useState } from 'react';
import './LGSPuanHesaplama.css';

const LGSPuanHesaplama = () => {
  const DERSLER = {
    turkce: { label: 'Türkçe', max: 20 },
    matematik: { label: 'Matematik', max: 20 },
    fen: { label: 'Fen Bilimleri', max: 20 },
    inkilap: { label: 'T.C. İnkılap Tarihi', max: 10 },
    din: { label: 'Din Kültürü', max: 10 },
    yabanciDil: { label: 'Yabancı Dil', max: 10 }
  };

  const [degerler, setDegerler] = useState(
    Object.keys(DERSLER).reduce((acc, ders) => ({
      ...acc,
      [ders]: { dogru: '', yanlis: '' }
    }), {})
  );

  const [sonuc, setSonuc] = useState(null);
  const [hata, setHata] = useState('');

  const handleChange = (ders, type, value) => {
    // Değeri sayıya çevir ve sınırla
    const rawValue = parseInt(value) || 0;
    const numValue = Math.max(Math.min(rawValue, DERSLER[ders].max), 0);
  
    // Maksimum değer kontrolleri
    const maxDogru = DERSLER[ders].max - (degerler[ders].yanlis || 0);
    const maxYanlis = DERSLER[ders].max - (degerler[ders].dogru || 0);
  
    // Değeri sınırla
    const constrainedValue = type === 'dogru'
      ? Math.min(numValue, maxDogru)
      : Math.min(numValue, maxYanlis);
  
    // State'i güncelle
    setDegerler(prev => ({
      ...prev,
      [ders]: {
        ...prev[ders],
        [type]: constrainedValue === 0 ? '' : constrainedValue
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const hataliDers = Object.entries(degerler).find(([ders, { dogru, yanlis }]) => {
      const toplam = (dogru || 0) + (yanlis || 0);
      return toplam > DERSLER[ders].max;
    });

    if(hataliDers) {
      setHata(`${DERSLER[hataliDers[0]].label} için maksimum ${DERSLER[hataliDers[0]].max} soru girebilirsiniz!`);
      return;
    }

    const netler = Object.entries(degerler).reduce((acc, [ders, { dogru, yanlis }]) => {
      const d = dogru || 0;
      const y = yanlis || 0;
      acc[ders] = (d - (y / 3)).toFixed(2);
      return acc;
    }, {});

    const puanlar = {
      turkce: (netler.turkce * 4.348).toFixed(2),
      matematik: (netler.matematik * 4.2538).toFixed(2),
      fen: (netler.fen * 4.1230).toFixed(2),
      inkilap: (netler.inkilap * 1.666).toFixed(2),
      din: (netler.din * 1.899).toFixed(2),
      yabanciDil: (netler.yabanciDil * 1.5075).toFixed(2)
    };

    const toplamPuan = (
      Object.values(puanlar).reduce((sum, puan) => sum + parseFloat(puan), 0) + 
      194.752082
    ).toFixed(2);

    setSonuc({ netler, puanlar, toplamPuan });
    setHata('');
  };

  const handleReset = () => {
    setDegerler(Object.keys(DERSLER).reduce((acc, ders) => ({
      ...acc,
      [ders]: { dogru: '', yanlis: '' }
    }), {}));
    setSonuc(null);
    setHata('');
  };

  return (
    <div className="lgs-container">
      <div className="lgs-header">
        <h1>LGS Puan Hesaplama</h1>
        <p>Doğru ve yanlış sayılarınızı girerek tahmini puanınızı hesaplayın</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="ders-grid">
          {Object.entries(DERSLER).map(([dersKey, { label, max }]) => (
            <div key={dersKey} className="ders-karti">
              <div className="ders-baslik">
                <h3>{label}</h3>
                <span>Max: {max}</span>
              </div>
              
              <div className="input-grubu">
                <input
                  type="number"
                  value={degerler[dersKey].dogru}
                  onChange={(e) => handleChange(dersKey, 'dogru', e.target.value)}
                  placeholder="Doğru"
                  min="0"
                  max={max}
                />
                <input
                  type="number"
                  value={degerler[dersKey].yanlis}
                  onChange={(e) => handleChange(dersKey, 'yanlis', e.target.value)}
                  placeholder="Yanlış"
                  min="0"
                  max={max - (degerler[dersKey].dogru || 0)}
                />
              </div>
            </div>
          ))}
        </div>

        {hata && <div className="hata-mesaji">{hata}</div>}

        <div className="button-container">
          <button type="button" className="temizle-btn" onClick={handleReset}>
            Temizle
          </button>
          <button type="submit" className="hesapla-btn">
            Hesapla
          </button>
        </div>
      </form>

      {sonuc && (
        <div className="sonuc-paneli">
          <h2>Hesaplama Sonuçları</h2>
          
          <div className="net-listesi">
            {Object.entries(DERSLER).map(([dersKey, { label }]) => (
              <div key={dersKey} className="net-item">
                <span>{label}</span>
                <div className="net-deger">
                  {sonuc.netler[dersKey]} Net
                </div>
              </div>
            ))}
          </div>

          <div className="toplam-puan">
            <span>Tahmini LGS Puanınız:</span>
            <div className="puan-gosterge">
              {sonuc.toplamPuan}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LGSPuanHesaplama;