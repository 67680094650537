import React, { useState } from 'react';
import './AlesPuanHesaplama.css';
import { formatNumber } from './utils/format'; // Sayı formatlama

const AlesPuanHesaplama = () => {
  const [sayisalDogru, setSayisalDogru] = useState('');
  const [sayisalYanlis, setSayisalYanlis] = useState('');
  const [sozelDogru, setSozelDogru] = useState('');
  const [sozelYanlis, setSozelYanlis] = useState('');
  const [sonuc, setSonuc] = useState(null);
  const [error, setError] = useState('');

  const handleHesapla = (e) => {
    e.preventDefault();
    setError('');

    // Sayısal ve sözel doğrular ve yanlışlar sayı olarak
    const sDogru = parseInt(sayisalDogru) || 0;
    const sYanlis = parseInt(sayisalYanlis) || 0;
    const sozDogru = parseInt(sozelDogru) || 0;
    const sozYanlis = parseInt(sozelYanlis) || 0;

    // Geçerlilik kontrolü
    if (sDogru + sYanlis > 50 || sozDogru + sozYanlis > 50) {
      setError('Sayısal ve Sözel toplamları 50\'yi geçemez.');
      return;
    }

    // Net hesaplama (4 yanlış 1 doğruyu götürür)
    const sayisalNet = sDogru - (sYanlis / 4);
    const sozelNet = sozDogru - (sozYanlis / 4);

    // ALES puanı hesaplama (formüle göre)
    const alesPuan = (sayisalNet * 1.5) + (sozelNet * 1.5);

    // Negatif puan çıkarsa sıfır yap
    const finalPuan = alesPuan < 0 ? 0 : alesPuan > 100 ? 100 : alesPuan;

    // Sonuçları kaydet
    setSonuc({
      sayisalNet: sayisalNet.toFixed(2),
      sozelNet: sozelNet.toFixed(2),
      alesPuan: finalPuan.toFixed(2)
    });
  };

  const handleTemizle = () => {
    setSayisalDogru('');
    setSayisalYanlis('');
    setSozelDogru('');
    setSozelYanlis('');
    setSonuc(null);
    setError('');
  };

  return (
    <div className="ales-container">
      <h1 className="ales-title">ALES Puan Hesaplama</h1>
      <p style={{ textAlign: 'center', marginBottom: '30px', fontSize: '18px', fontFamily: 'Poppins' }}>
        ALES sınavı için sayısal ve sözel netlerinize göre puanınızı hesaplayın.
      </p>
      <form className="ales-form" onSubmit={handleHesapla}>
        <div className="ales-section">
          <h3>Sayısal</h3>
          <label>
            * Doğru Sayısı:
            <input type="number" value={sayisalDogru} onChange={(e) => setSayisalDogru(e.target.value)} placeholder="Örn: 40" min="0" max="50" />
          </label>
          <label>
            * Yanlış Sayısı:
            <input type="number" value={sayisalYanlis} onChange={(e) => setSayisalYanlis(e.target.value)} placeholder="Örn: 10" min="0" max="50" />
          </label>
        </div>
        <div className="ales-section">
          <h3>Sözel</h3>
          <label>
            * Doğru Sayısı:
            <input type="number" value={sozelDogru} onChange={(e) => setSozelDogru(e.target.value)} placeholder="Örn: 35" min="0" max="50" />
          </label>
          <label>
            * Yanlış Sayısı:
            <input type="number" value={sozelYanlis} onChange={(e) => setSozelYanlis(e.target.value)} placeholder="Örn: 15" min="0" max="50" />
          </label>
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="button-group">
          <button type="submit" className="hesapla-button">Hesapla</button>
          <button type="button" className="temizle-button" onClick={handleTemizle}>Temizle</button>
        </div>
      </form>

      {/* Eğer sonuç varsa, sonuçları göster */}
      {sonuc ? (
        <div className="chart-container">
          <div style={{ width: '100%' }}>
            <p><strong>Sayısal Net:</strong> {sonuc.sayisalNet}</p>
            <div className="progress-bar">
              <div className="progress-fill progress-sayisal" style={{ width: `${(sonuc.sayisalNet / 50) * 100}%` }}>
                {sonuc.sayisalNet}
              </div>
            </div>
            <p><strong>Sözel Net:</strong> {sonuc.sozelNet}</p>
            <div className="progress-bar">
              <div className="progress-fill progress-sozel" style={{ width: `${(sonuc.sozelNet / 50) * 100}%` }}>
                {sonuc.sozelNet}
              </div>
            </div>
            <p><strong>ALES Puanı:</strong> {sonuc.alesPuan}</p>
            <div className="progress-bar">
              <div className="progress-fill progress-ales" style={{ width: `${(sonuc.alesPuan / 100) * 100}%` }}>
                {sonuc.alesPuan}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Lütfen verilerinizi girin ve "Hesapla" butonuna tıklayın.</p>
      )}

      {/* Sonuçları göster */}
      {sonuc && (
        <div className="sonuc-card">
          <h2>Sonuçlar</h2>
          <p><strong>Sayısal Net:</strong> {formatNumber(sonuc.sayisalNet)}</p>
          <p><strong>Sözel Net:</strong> {formatNumber(sonuc.sozelNet)}</p>
          <p><strong>Toplam ALES Puanı:</strong> {formatNumber(sonuc.alesPuan)} Puan</p>
        </div>
      )}
    </div>
  );
};

export default AlesPuanHesaplama;
